import {useEffect, useState} from 'react'

import Arrow from '../Arrow/Arrow'
import NavSubItem from '../NavSubItem/NavSubItem'

import type {ReactElement} from 'react'
import type INavItem from './interfaces/INavItem'

import {useAppSelector} from '../../store/hooks/useApp'
import categoriesSelector from '../../store/slices/categories/selectors'

import styles from './sass/NavItem.module.scss'

export default function NavItem({
  icon,
  title,
  subCategories,
  onClickHandler,
  openedItem,
  isSidebarOpened,
  id,
}: INavItem): ReactElement {
  const {category} = useAppSelector(categoriesSelector)
  const [activeItemHeader, setActiveItemHeader] = useState('')

  useEffect(() => {
    if (category) {
      setActiveItemHeader(category.id)
    }
  }, [category])

  const getItemHeaderStyles = () => {
    if (activeItemHeader === id && !isSidebarOpened) {
      return styles.activeItemHeader
    }
    if (!isSidebarOpened) {
      return styles.shortItemHeader
    }
    return ''
  }

  return (
    <li className={styles.item}>
      <div
        className={`${styles.itemHeader} ${getItemHeaderStyles()}`}
        role='presentation'
        onClick={() => onClickHandler(title)}
      >
        <img src={icon} alt='icon' className={styles.icon} />
        <div className={`${styles.titleGroup} ${!isSidebarOpened ? styles.shortTitleGroup : ''}`}>
          <span className={styles.title}>{title}</span>
          <Arrow isOpen={openedItem === title} />
        </div>
      </div>
      <ul
        className={`${styles.submenu} 
        ${openedItem === title ? styles.active : styles.collapse}
        `}
      >
        {subCategories
          .filter((sub) => !!sub.actionId)
          .map(({id: subcategoryId, name, parentCategory}) => (
            <NavSubItem
              key={subcategoryId}
              title={name}
              isSidebarOpened={isSidebarOpened}
              parentCategoryId={parentCategory || id}
              parentCategory={{id, name: title, subCategories, parentCategory}}
              id={subcategoryId}
            />
          ))}
      </ul>
    </li>
  )
}
