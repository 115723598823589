import FormSelect from 'react-bootstrap/FormSelect'
import Form from 'react-bootstrap/Form'

export interface Props {
  onSelect: (value: string) => void
}

export function DashboardSelect({onSelect}: Props) {
  return (
    <Form.Select
      defaultValue=''
      aria-label='Default select example'
      onChange={({currentTarget}) => onSelect(currentTarget.value)}
    >
      <option value='' disabled>
        Select the module
      </option>
      <option value='agedcareai'>Insights</option>
      <option value='assist'>Assist</option>
    </Form.Select>
  )
}
