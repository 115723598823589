/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import clsx from 'clsx'
import {Dispatch, FC, SetStateAction, useEffect} from 'react'
import {KTIcon} from '../../../helpers'
import {useCurrentUser} from '../../../../queries/getCurrentUser'
import {useNavigate} from 'react-router-dom'
import {useLayout} from '../../core'
import {useExternalApplications} from '../../../../queries/getExternalApplications'
import {useThemeMode} from '../../../partials'
import {useLocation} from 'react-router'

const tabs: ReadonlyArray<{link: string; icon: string; tooltip: string}> = [
  // {
  //   link: 'subscription',
  //   icon: 'chart-simple',
  //   tooltip: 'Subscription',
  // },
]

type Props = {
  link: string
  setLink: Dispatch<SetStateAction<string>>
}

const AsideTabs: FC<Props> = ({link, setLink}) => {
  const applications = useExternalApplications()
  const user = useCurrentUser()
  const navigate = useNavigate()
  const theme = useThemeMode()
  const layout = useLayout()
  const isAdmin = user?.data?.roles?.includes('Admin')
  const {pathname} = useLocation()
  const ASK_ME_PATH = '/askme'

  useEffect(() => {
    const isAskMe = pathname.includes(ASK_ME_PATH)
    if (isAskMe) {
      setLink('askme')
    }
  }, [pathname])

  return (
    <div
      className='hover-scroll-y mb-10'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-wrappers='#kt_aside_nav'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-offset='0px'
    >
      {/* begin::Nav */}
      <ul className='nav flex-column' id='kt_aside_nav_tabs'>
        {/* begin::Nav item */}
        {tabs.map((t) => (
          <li key={t.link}>
            {/* begin::Nav link */}
            <a
              className={clsx(
                'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
                {active: t.link === link}
              )}
              onClick={() => {
                layout.config.aside.secondaryDisplay = true
                layout.setLayout(layout)
                setLink(t.link)
              }}
            >
              <KTIcon iconName={t.icon} className='fs-2x' />
            </a>
            {/* end::Nav link */}
          </li>
        ))}
        {/* <li key={'data-analytics'}> */}
        {/* begin::Nav link */}
        {/* <a
            className={clsx(
              'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
              {active: 'data-analytics' === link}
            )}
            onClick={() => {
              layout.config.aside.secondaryDisplay = false
              layout.setLayout(layout)
              setLink('data-analytics')
              navigate('/data-analytics')
            }}
          >
            <div style={{border: 'solid', borderRadius: '100px', width: '2rem'}}>
              <i className='fa-solid fa-f'></i>
            </div>
          </a> */}
        {/* end::Nav link */}
        {/* </li> */}

        {/* <li key={'external-software'}>
          <a
            className={clsx(
              'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
              {active: 'external-software' === link}
            )}
            onClick={() => {
              layout.config.aside.secondaryDisplay = false
              layout.setLayout(layout)
              setLink('external-software')
              navigate('/external-software')
            }}
          >
            <i className='fa-solid fa-window-restore'></i>
          </a>
        </li> */}

        {/* <li key={'data-warehouse'}> */}
        {/* begin::Nav link */}
        {/* <a
            className={clsx(
              'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
              {active: 'data-warehouse' === link}
            )}
            onClick={() => {
              layout.config.aside.secondaryDisplay = true
              layout.setLayout(layout)
              setLink('data-warehouse')
            }}
          >
            <img
              src={'/media/logos/power-bi-logo.png'}
              alt={'PowerBi logo'}
              className='h-50 align-self-center'
            />
          </a> */}
        {/* end::Nav link */}
        {/* </li> */}

        <li key={'agedcareai'}>
          {/* begin::Nav link */}
          <a
            className={clsx(
              'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
              {active: 'agedcareai' === link}
            )}
            onClick={() => {
              layout.config.aside.secondaryDisplay = false
              layout.setLayout(layout)
              setLink('agedcareai')
              navigate('/agedcareai')
            }}
          >
            <img
              src={'/media/logos/Main Menu_ Insights.png'}
              alt={'Pythia logo'}
              height='34'
              width='34'
              className='align-self-center'
            />
          </a>
          {/* end::Nav link */}
        </li>

        <li key={'askMe'}>
          {/* begin::Nav link */}
          <a
            className={clsx(
              'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
              {active: 'askme' === link}
            )}
            onClick={() => {
              layout.config.aside.secondaryDisplay = false
              layout.setLayout(layout)
              setLink('askme')
              navigate(ASK_ME_PATH)
            }}
          >
            <img
              src='/media/logos/Main Menu_ AskMe .png'
              height='34'
              width='34'
              alt='Ask-Me logo'
              className='align-self-center'
            />
          </a>
          {/* end::Nav link */}
        </li>

        <li key={'assist'}>
          {/* begin::Nav link */}
          <a
            className={clsx(
              'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
              {active: 'assist' === link}
            )}
            onClick={() => {
              layout.config.aside.secondaryDisplay = false
              layout.setLayout(layout)
              setLink('assist')
              navigate('/assist/my-files')
            }}
          >
            <img
              height='34'
              width='34'
              src={'/media/logos/Main Menu_ Assist.png'}
              alt={'Aged Care Assist logo'}
            />
          </a>
          {/* end::Nav link */}
        </li>
        {/* 
        {applications.data &&
          applications.data.map((application) => (
            <li key={application.id!}> */}
        {/* begin::Nav link */}
        {/* <a
                className={clsx(
                  'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
                  {active: application.applicationName! === link}
                )}
                onClick={() => {
                  layout.config.aside.secondaryDisplay = false
                  layout.setLayout(layout)
                  setLink(application.applicationName!)
                  navigate(`/application/${application.id!}`)
                }}
              >
                <div>
                  <img
                    width={'32px'}
                    height={'32px'}
                    src={`data:image/jpeg;base64,${
                      theme.mode === 'light'
                        ? application.appImageLight
                        : application.iconUrlDarkMode!
                    }`} */}
        {/* // src='https://www.pmc.gov.au/sites/default/files/styles/full_width/public/2023-03/coat-of-arms-stylised-web.png?itok=pp6omWWw'
                    alt=''
                  />
                </div>
              </a> */}
        {/* end::Nav link */}
        {/* </li>
          ))} */}

        {isAdmin && (
          <li key={'configuration'}>
            {/* begin::Nav link */}
            <a
              className={clsx(
                'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
                {active: 'configuration' === link}
              )}
              onClick={() => {
                layout.config.aside.secondaryDisplay = true
                layout.setLayout(layout)
                setLink('configuration')
              }}
            >
              <img
                height='34'
                width='34'
                src={'/media/logos/Main Menu_ Admin Menu.png'}
                alt={'Aged Care Assist logo'}
              />
            </a>
            {/* end::Nav link */}
          </li>
        )}
        {/* end::Nav link */}
      </ul>
      {/* end::Tabs */}
    </div>
  )
}

export {AsideTabs}
