export enum ButtonVariant {
  PRIMARY = 'primary',
  BLUE = 'blue',
  GRAY_TEXT = 'grayText',
}

export enum ButtonWidthVariant {
  AI = 'ai',
  APPLY = 'apply',
  EXPLORE = 'explore',
}
