import {Button} from 'primereact/button'
import {Column} from 'primereact/column'
import {DataTable, DataTableSelectionMultipleChangeEvent} from 'primereact/datatable'
import {Divider} from 'primereact/divider'
import {useNavigate, useParams} from 'react-router-dom'
import {useUser} from '../../../../../queries/getUser'
import {useRoles} from '../../../../../queries/getRoles'
import {useAssignRole} from '../../../../../queries/assignRoleToUser'
import {useRemoveRole} from '../../../../../queries/removeRoleFromUser'

export const ManageRolesView = () => {
  const navigate = useNavigate()
  const {userId} = useParams()
  const user = useUser(userId ?? '')
  const roles = useRoles({page: 1, pageSize: 40, search: ''})
  const assignRoles = useAssignRole()
  const unAssignRoles = useRemoveRole()
  const handleBackClick = () => {
    navigate(-1)
  }

  const handleSelect = (e: DataTableSelectionMultipleChangeEvent<{role: string}[]>) => {
    const selectedRoles = e.value?.map((role) => role.role) || []

    const rolesToUnassign = user.data?.roles?.filter(
      (role) => role && !selectedRoles.includes(role)
    )

    const rolesToAssign = selectedRoles.filter((role) => !user.data?.roles?.some((r) => r === role))

    if (rolesToUnassign) {
      rolesToUnassign.forEach((role) => {
        unAssignRoles.mutate({userId: userId ?? '', roleNames: [role ?? '']})
      })
    }

    if (rolesToAssign) {
      rolesToAssign.forEach((role) => {
        assignRoles.mutate({userId: userId ?? '', roleName: role ?? ''})
      })
    }
  }
  return (
    <div style={{padding: '1rem'}}>
      <Button icon='pi pi-arrow-left' text size='large' onClick={handleBackClick} />
      <div style={{margin: '2rem'}}>
        <h2>Manage Roles</h2>
        <Divider />
        {/* <div style={{display: 'flex', gap: '2rem'}}>
          <Button label='Assign Roles' icon='pi pi-plus' text pt={{root: {style: {padding: 0}}}} />
          {selectedRoles.length > 0 && (
            <Button
              label='Remove Roles'
              icon='pi pi-trash'
              text
              pt={{root: {style: {padding: 0}}}}
            />
          )}
        </div> */}
        <div style={{marginTop: '2rem'}}>
          <DataTable
            onSelectionChange={handleSelect}
            selectionMode={null}
            value={roles.data?.items?.map((role) => ({role: role.name} ?? '')) ?? []}
            loading={user.isLoading}
            selection={
              user.data?.roles?.map((role) => ({
                role: role ?? '',
              })) ?? []
            }
          >
            <Column selectionMode='multiple' headerStyle={{width: '3rem'}} />
            sortable <Column field='role' header='Name' />
          </DataTable>
        </div>
      </div>
    </div>
  )
}
