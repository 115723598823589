import type {ReactElement} from 'react'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

import Modal from '../../../../components/Modal/Modal'
import {useAppSelector} from '../../../../store/hooks/useApp'

import ServiceItem from '../ServiceItem/ServiceItem'
import AnalysisCategory from './AnalysisCategory'
import type {IIncidentBlockModal} from './interfaces/IIncidentBlockModal'

import closeIcon from 'src/app/common/assets/icons/close.svg'

import styles from './sass/IncidentBlockModal.module.scss'
import {useGetPythiaServicesByStatusQuery} from '../../queries/getPythiaServicesByStatus'

import {Form} from 'react-bootstrap'
import DotsLoader from '../../../../../../components/DotsLoader/DotsLoader'
import contentItemsSelector from '../../../../../../common/store/slices/contentItems/selectors'
import {useGetOracleOfferingQuery} from '../../../../../../common/store/slices/contentItems/apis/contentItems'
import {IOracleOffering} from '../../../../../../common/store/slices/contentItems/interfaces/IOracleOfferingResponse'
import {
  setContentItemsState,
  toggleModal,
} from '../../../../../../common/store/slices/contentItems/slice'

export default function IncidentBlockModal({
  isModalOpen,
  activeIncident,
  analysisCategories,
}: IIncidentBlockModal): ReactElement {
  const dispatch = useDispatch()
  const {activeOracleOffering = []} = useAppSelector(contentItemsSelector)

  const {data: oracleOffering, isFetching} = useGetOracleOfferingQuery()
  const pythiaServices = useGetPythiaServicesByStatusQuery(activeIncident?.status.id ?? '')
  const [activeOracleOfferingWithAnalysis, setActiveOracleOfferingWithAnalysis] = useState<any[]>(
    []
  )
  const [search, setSearch] = useState('')

  useEffect(() => {
    dispatch(
      setContentItemsState({
        activeAnalysisCategory: analysisCategories?.[0],
        activeOracleOffering: pythiaServices?.data
          ? (pythiaServices.data?.filter(({id}) =>
              analysisCategories?.[0]?.promptIds?.includes(id ?? '')
            ) as IOracleOffering[])
          : [],
      })
    )
  }, [analysisCategories, dispatch, pythiaServices.data])

  useEffect(() => {
    if (search === '') {
      const combinedResult = activeOracleOffering?.map((item) => {
        const analysis = activeIncident?.analysisOutcomes?.find(
          ({serviceId}) => serviceId === item.id
        )
        return {
          ...item,
          notes: item.notes,
          analysis: analysis?.analysis,
          analysisId: analysis?.id,
          dateGenerated: analysis?.dateGenerated,
        }
      })
      setActiveOracleOfferingWithAnalysis(combinedResult)
    }

    if (search !== '') {
      const analysisItems =
        activeIncident?.analysisOutcomes?.map((analysis) => {
          const service = oracleOffering?.find(({id}) => id === analysis.serviceId)
          return {
            ...service,
            notes: service?.notes,
            analysis: analysis.analysis,
            analysisId: analysis.id,
            dateGenerated: analysis.dateGenerated,
          }
        }) ?? []
      const result = analysisItems.filter(
        (item) =>
          item?.name?.toLowerCase().includes(search.toLowerCase()) ||
          item?.notes?.toLowerCase().includes(search.toLowerCase())
      )
      setActiveOracleOfferingWithAnalysis(result)
    }
  }, [dispatch, activeIncident, activeOracleOffering, search, oracleOffering])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleClearSearch = () => {
    setSearch('')
  }

  const sortAlphabetically = (a: any, b: any) => {
    return a.name.trim() > b.name.trim() ? 1 : -1
  }

  return (
    <Modal isShow={isModalOpen}>
      <div className={styles.modalContainer}>
        <div className={styles.header}>
          <h2 className={styles.title}>{activeIncident?.subject}</h2>
          <div className={styles.search}>
            <Form.Control onChange={handleSearch} placeholder='Search' value={search} />
            <div
              className={styles.icon}
              role='presentation'
              onClick={() => dispatch(toggleModal())}
            >
              <img src={closeIcon} alt='close' />
            </div>
          </div>
        </div>
        <div className={styles.contentBlock}>
          <div className={styles.analysisCategories}>
            {[...(analysisCategories ?? [])].map((item) => (
              <AnalysisCategory
                key={item.id}
                item={item}
                oracleOffering={(pythiaServices.data ?? []) as IOracleOffering[]}
                hasSearch={search !== ''}
              />
            ))}
          </div>
          {isFetching ? (
            <DotsLoader variant='centered' />
          ) : (
            <div className={styles.services}>
              {[...activeOracleOfferingWithAnalysis]
                ?.sort(sortAlphabetically)
                ?.map(({id, name, notes, analysis, analysisId, dateGenerated}) => (
                  <ServiceItem
                    key={id}
                    title={name}
                    content={notes}
                    id={id}
                    activeIncidentId={activeIncident ? activeIncident?.id : ''}
                    analysisOutcomes={analysis}
                    analysisOutcomeId={analysisId}
                    dateGenerated={dateGenerated}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
