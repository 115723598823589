import {useQuery} from '@tanstack/react-query'

import {ExternalApplicationsService} from '../api'

export const useExternalApplication = (id?: string) =>
  useQuery({
    queryFn: () => ExternalApplicationsService.externalApplicationsGet(id!),
    enabled: !!id,
    queryKey: ['externalApplications', id],
  })
