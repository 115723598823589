import SuggestionsItem from '../SuggestionsItem/SuggestionsItem';

import type { ReactElement } from 'react';
import type { ISuggestionsList } from './interfaces/ISuggestionsList';

import styles from './sass/SuggestionsList.module.scss';

export default function SuggestionsList({
  suggestions,
}: ISuggestionsList): ReactElement {
  return (
    <ul className={styles.list}>
      {suggestions?.map(({
        id, text, category, subCategory,
      }) => (
        <SuggestionsItem
          key={id}
          id={id}
          text={text}
          category={category.name}
          subCategory={subCategory.name}
        />
      ))}
    </ul>
  );
}
