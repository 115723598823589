import {Button} from 'primereact/button'
import {Divider} from 'primereact/divider'
import {useNavigate, useParams} from 'react-router-dom'
import {useResetPassword} from '../../../../../queries/resetUserPassword'
import {useState} from 'react'
import {InputText} from 'primereact/inputtext'

export const ResetPasswordView = () => {
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const resetPassword = useResetPassword()
  const {userId} = useParams()

  const handleBackClick = () => {
    navigate(-1)
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleSubmit = () => {
    resetPassword.mutate(
      {id: userId ?? '', password},
      {
        onSuccess: () => {
          navigate(-1)
        },
      }
    )
  }
  return (
    <div style={{padding: '1rem'}}>
      <Button icon='pi pi-arrow-left' text size='large' onClick={handleBackClick} />
      <div style={{margin: '2rem'}}>
        <h2>Reset Password</h2>
        <Divider />
        <div
          style={{
            marginTop: '2rem',
            display: 'flex',
            gap: '0.5rem',
            flexDirection: 'column',
            width: '30rem',
          }}
        >
          <label htmlFor='password'>Password</label>
          <InputText
            id='password'
            type='password'
            aria-describedby='username-help'
            onChange={handlePasswordChange}
            value={password}
          />
          <small id='username-help'>Enter new password.</small>
        </div>
        <Button label='Submit' rounded onClick={handleSubmit} loading={resetPassword.isPending} />
      </div>
    </div>
  )
}
