import {useQuery} from '@tanstack/react-query'
import {ContentItemService, RoleDto} from '../../../api'


export const useTypeRoles = (id: string) =>
  useQuery({
    queryFn: () => ContentItemService.contentItemGetContentItemTypeRoles(id),
    queryKey: ['typeRoles', id],
    select: (data) => data.map((role) => ({id: role.id, name: role.name} as RoleDto)) ?? [],
  })
