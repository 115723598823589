import {Form} from 'react-bootstrap'
import {ButtonVariant, ButtonWidthVariant} from '../../../../../common/constants/enams'
import Button from '../../../../PythiaV3/components/Button/Button'
import {useState} from 'react'

interface Props {
  onPrompt?: (prompt: string) => void
}

export const PromptRequest = ({onPrompt}: Props) => {
  const [text, setText] = useState<string>('')

  return (
    <div>
      <h3 style={{marginBottom: '2.5rem'}}>Prompt</h3>
      <div style={{padding: '16px 24px'}}>
        <Form>
          <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
            <Form.Label>Type your own prompt and hit "Send"</Form.Label>
            <Form.Control as='textarea' rows={3} onChange={({target}) => setText(target.value)} />
          </Form.Group>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              title='AI Request'
              onClick={() => onPrompt && onPrompt(text)}
              variant={ButtonVariant.PRIMARY}
              widthVariant={ButtonWidthVariant.EXPLORE}
            />
          </div>
        </Form>
      </div>
    </div>
  )
}
