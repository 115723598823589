import {useMutation} from '@tanstack/react-query'
import {CategoriesService} from 'src/api'
import {UpdateCategoryCommand} from 'src/api/models/UpdateCategoryCommand'
import {queryClient} from 'src/libs/react-query'

const updateCategory = async (request: UpdateCategoryCommand) => {
  return await CategoriesService.categoriesUpdateCategory(request)
}

export const useUpdateCategory = () => {
  return useMutation({
    mutationFn: updateCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['askMeCategories']})
    },
  })
}
