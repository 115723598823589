import type {ReactElement} from 'react'
import {useState} from 'react'

import {ISearchInputBlock, ISearchInputBlockItem} from './interfaces/ISearchInputBlock'

import searchIcon from 'src/app/common/assets/icons/search.svg'

import styles from './sass/SearchInputBlock.module.scss'
import {IContentItem} from '../../../../../../common/store/slices/contentItems/interfaces/IContentItemsResponse'

export default function SearchInputBlock({
                                           activeIncident,
                                           setActiveIncident,
                                           complaints,
                                           setSearchTerm,
                                           searchTerm,
                                         }: ISearchInputBlock): ReactElement {
  const [prevIncidentIndex, setPrevIncidentIndex] = useState<number | undefined>(undefined)
  const onItemClick = (complaintsItem: ISearchInputBlockItem, index: number) => {
    setActiveIncident(complaintsItem)
    setPrevIncidentIndex(index - 1)
  }

  return (
    <div className={styles.searchInputBlock}>
      <div className={styles.searchInput}>
        <div className={styles.searchIcon}>
          <img src={searchIcon} alt="search" />
        </div>
        <div className={styles.inputField}>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            aria-label="Search"
            placeholder="Search"
          />
        </div>
      </div>
      <ul className={styles.incidentList}>
        {complaints?.map(({
                            id, subject, content, ...rest
                          }, index) => (
          <li
            key={id}
            className={`
            ${styles.incident} 
            ${id === activeIncident?.id ? styles.active : ''}
            ${index === prevIncidentIndex ? styles.prevActiveItem : ''}
            `}
            onClick={() => onItemClick({
              id, subject, content, ...rest,
            }, index)}
            role="presentation"
          >
            <h4 className={styles.title}>{subject}</h4>
            <p className={styles.description}>{content}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}
