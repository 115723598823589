import {ReactElement, useCallback, useMemo} from 'react'
import {useEffect, useState} from 'react'
import {format} from 'date-fns'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Button from '../../../../components/Button/Button'
import {ButtonVariant, ButtonWidthVariant} from '../../../../../../common/constants/enams'

import StandardsIconsBlock from '../StandardsIconsBlock/StandardsIconsBlock'
// import ParsedAnalysis from './ParsedAnalysis'
import ParsedAnalysis from './ParsedAnalysis'
import type {IServiceItem} from './interfaces/IServiceItem'

import styles from './sass/ServiceItem.module.scss'
import {useGetAnalysisOutcomeQuery} from '../../queries/getAnalysisOutcome'
import {ProcessingStatus} from '../../../../../../../api'
import {useDeleteAnalysisOutcome} from '../../../../api/deleteAnalysisOutcome'
import {
  useFetchContentItemQuery,
  useGenerateAnalysisMutation,
} from '../../../../../../common/store/slices/contentItems/apis/contentItems'

export default function ServiceItem({
  title,
  content,
  activeIncidentId,
  id,
  analysisOutcomes,
  analysisOutcomeId,
  dateGenerated,
}: IServiceItem): ReactElement {
  const [isGenerate, setIsGenerate] = useState(false)
  const analysisOutcome = useGetAnalysisOutcomeQuery(analysisOutcomeId)
  const [isViewed, setIsViewed] = useState(false)
  const [analysisId, setAnalysisId] = useState<string>('')
  const [analysis, setAnalysis] = useState<string | undefined>(undefined)
  const [generateAnalysis, {isLoading}] = useGenerateAnalysisMutation()
  const {data, refetch} = useFetchContentItemQuery({
    id: activeIncidentId,
  })
  const onExploreClickHandler = async (resetData?: boolean) => {
    const result = await generateAnalysis({
      id: activeIncidentId,
      serviceId: id,
      resetData: resetData ? 'true' : 'false',
    }).unwrap()
    setAnalysisId(result.analysisId)
    setIsGenerate(true)
    refetch()
  }
  const deleteOutcome = useDeleteAnalysisOutcome()

  useEffect(() => {
    if (analysisOutcomes) {
      setIsGenerate(true)
    }
  }, [analysisOutcomes])

  const onViewClickHandler = () => {
    const newAnalysis = data?.analysisOutcomes?.find(
      ({id: analysisOutcomesId}) => analysisOutcomesId === analysisId
    )
    setAnalysis(newAnalysis?.analysis)
    setIsViewed(!isViewed)
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(analysisOutcomes)
  }

  const handleRegenerate = async () => {
    setIsGenerate(false)
    setIsViewed(false)
    await onExploreClickHandler(true)
  }

  const handleDeleteOutcome = () => {
    deleteOutcome.mutate(analysisOutcomeId, {onSuccess: () => refetch()})
  }

  const getDate = () => {
    const date = new Date(dateGenerated)

    if (date.getFullYear() > 2000) return date

    return new Date()
  }

  const dateText = `Last generation date: ${format(getDate(), 'dd/MM/yyyy')}`

  const mailLink = useMemo(() => {
    // get the content of the subject by getting the content between subject: and first \n
    const subject =
      analysisOutcome.data?.analysis?.match(/Subject:(.*)\n/)?.[1]?.replace('Subject:', '') ?? ''
    // get the content of the email by getting the content after the first \n
    const body = analysisOutcome.data?.analysis?.split('\n').join('\n')

    if (!body) return ''

    return `mailto:?subject=${encodeURI(subject)} body=${encodeURI(body)}`
  }, [analysisOutcome.data?.analysis])

  return (
    <div className={`${styles.serviceItem} ${isViewed && styles.borderBottomUnset}`}>
      <div className={styles.header}>
        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.text}>{content}</p>
        </div>
        {analysisOutcome.data?.processingStatus === ProcessingStatus._1 ? (
          <Button
            title={isViewed ? 'Hide' : 'View'}
            onClick={onViewClickHandler}
            variant={ButtonVariant.BLUE}
            widthVariant={ButtonWidthVariant.EXPLORE}
          />
        ) : (
          <div style={{display: 'flex'}}>
            {(analysisOutcome.data?.processingStatus === ProcessingStatus._0 ||
              analysisOutcome.isLoading) && (
              <Button
                title='x'
                onClick={handleDeleteOutcome}
                variant={ButtonVariant.GRAY_TEXT}
                // widthVariant={ButtonWidthVariant.EXPLORE}
              />
            )}
            <Button
              title='AI Request'
              onClick={onExploreClickHandler}
              variant={ButtonVariant.PRIMARY}
              widthVariant={ButtonWidthVariant.EXPLORE}
              isLoading={
                analysisOutcome.data?.processingStatus === ProcessingStatus._0 ||
                analysisOutcome.isLoading
              }
            />
          </div>
        )}
      </div>
      {isViewed ? (
        <div className={styles.standards}>
          <StandardsIconsBlock
            analysisId={analysisOutcomeId}
            onCopy={handleCopy}
            onRegenerate={handleRegenerate}
            mailLink={mailLink}
          />
          <div className={styles.additionalContentContainer}>
            <div className={styles.additionalContent}>
              <Markdown remarkPlugins={[remarkGfm]}>{analysisOutcome.data?.analysis}</Markdown>
            </div>
          </div>
          <div className={styles.dateBlock}>
            <div className={styles.date}>{dateText}</div>
            <StandardsIconsBlock
              analysisId={analysisOutcomeId}
              onCopy={handleCopy}
              onRegenerate={handleRegenerate}
              mailLink={mailLink}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}
