/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserModel } from '../models/CreateUserModel';
import type { PaginatedUsers } from '../models/PaginatedUsers';
import type { ResetPasswordDto } from '../models/ResetPasswordDto';
import type { UserRoleModel } from '../models/UserRoleModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IdentityService {

    /**
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static identityCreateRole(
requestBody: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Identity/CreateRole',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static identityAddRoleToUser(
requestBody: UserRoleModel,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Identity/addRoleToUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static identityCreateUser(
requestBody: CreateUserModel,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Identity/createUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static identityResetUserPassword(
id: string,
requestBody: ResetPasswordDto,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Identity/{id}/resetUserPassword',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param page 
     * @param pageSize 
     * @param search 
     * @param sortBy 
     * @param sortDescending 
     * @returns PaginatedUsers 
     * @throws ApiError
     */
    public static identityGetUsers(
page?: number,
pageSize?: number,
search?: string | null,
sortBy?: string | null,
sortDescending?: boolean,
): CancelablePromise<PaginatedUsers> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Identity/getUsers',
            query: {
                'Page': page,
                'PageSize': pageSize,
                'Search': search,
                'SortBy': sortBy,
                'SortDescending': sortDescending,
            },
        });
    }

    /**
     * @param id 
     * @returns binary 
     * @throws ApiError
     */
    public static identityDeleteUser(
id: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Identity/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param userId 
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static identityRemoveRolesFromUser(
userId: string,
requestBody: Array<string>,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Identity/remove-roles/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
