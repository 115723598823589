import {useMutation} from '@tanstack/react-query'
import {CategoriesService} from 'src/api'
import {queryClient} from 'src/libs/react-query'

const deleteCategory = async (id: string) => {
  console.log('deleteCategory', id)
  return await CategoriesService.categoriesDeleteCategory(id)
}

export const useDeleteCategory = () => {
  return useMutation({
    mutationFn: deleteCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['askMeCategories']})
    },
  })
}
