import {useMutation} from '@tanstack/react-query'
import {queryClient} from '../libs/react-query'
import {AccountService} from '../api'

export const useAssignRole = () =>
  useMutation({
    mutationFn: AccountService.accountAddRoleToUser,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['userRoles']})
      queryClient.invalidateQueries({queryKey: ['users']})
    },
  })

export type RemoveRolesOptions = {
  userId: string
  roles: string[]
}

const removeRolesFromUser = async ({userId, roles}: RemoveRolesOptions) =>
  await AccountService.accountRemoveRolesFromUser(userId, roles)

export const useRemoveRole = () =>
  useMutation({
    mutationFn: removeRolesFromUser,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['userRoles']})
      queryClient.invalidateQueries({queryKey: ['users']})
    },
  })
