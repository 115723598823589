import type {ReactElement} from 'react'
import type {IArrowButton} from './interfaces/IArrowButton'

import arrowLeft from 'src/app/common/assets/icons/whiteSidebar/arrow_left.svg'

import styles from './sass/ArrowButton.module.scss'

export default function ArrowButton({
  onArrowButtonClick,
  isSidebarOpened,
}: IArrowButton): ReactElement {
  return (
    <div
      className={`${styles.arrowButton} ${isSidebarOpened ? styles.open : ''}`}
      onClick={onArrowButtonClick}
      role='presentation'
    >
      <img src={arrowLeft} alt='arrow' />
    </div>
  )
}
