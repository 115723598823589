import { SUGGESTIONS_PAGE_SIZE } from '../../constants/general';
import useDebounce from '../../hooks/useDebounce';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import { useFetchSuggestionsQuery } from '../../store/slices/suggestions/apis/suggestions';
import suggestionsSelector from '../../store/slices/suggestions/selectors';
import { setSuggestionsState } from '../../store/slices/suggestions/slice';
import SuggestionsView from './SuggestionsView';

import type { ReactElement } from 'react';

function Suggestions(): ReactElement {
  const dispatch = useAppDispatch();
  const { page, searchTerm } = useAppSelector(suggestionsSelector);
  const debouncedSearchTerm = useDebounce(searchTerm.trim());

  const { data } = useFetchSuggestionsQuery({
    search: debouncedSearchTerm,
    pageNumber: page,
    pageSize: SUGGESTIONS_PAGE_SIZE,
  });

  const onPageClick = (pageNumber: number) => {
    dispatch(setSuggestionsState({ page: pageNumber }));
  };

  const onChangeHandler = (value: string) => {
    dispatch(setSuggestionsState({
      searchTerm: value,
      page: 1,
    }));
  };

  return (
    <SuggestionsView
      suggestions={data}
      searchTerm={searchTerm}
      onPageClick={onPageClick}
      onChangeHandler={onChangeHandler}
    />
  );
}

export default Suggestions;
