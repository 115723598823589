/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {AssignDashboardCategory} from '../models/AssignDashboardCategory';
import type {CreateDashboardCategoryDto} from '../models/CreateDashboardCategoryDto';
import type {DashboardCategoryDto} from '../models/DashboardCategoryDto';
import type {DashboardDto} from '../models/DashboardDto';
import type {DashboardRoleDto} from '../models/DashboardRoleDto';
import type {DashboardRoleUpdateRecord} from '../models/DashboardRoleUpdateRecord';

import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';
import {Dashboard} from "../models/Dashboard";

export class DashboardsService {

  /**
   * @returns DashboardDto
   * @throws ApiError
   */
  public static dashboardsGetAllDashboards(): CancelablePromise<Array<DashboardDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Dashboards',
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static dashboardsDeleteDashboard(
    id: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Dashboards/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param id
   * @returns DashboardDto
   * @throws ApiError
   */
  public static dashboardsGetDashboard(
    id: string,
  ): CancelablePromise<DashboardDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Dashboards/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param requestBody
   * @returns binary
   * @throws ApiError
   */
  public static dashboardsAssignRoleToDashboard(
    requestBody: DashboardRoleDto,
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Dashboards/assign-role',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody
   * @returns binary
   * @throws ApiError
   */
  public static dashboardsCreateDashboardCategory(
    requestBody: CreateDashboardCategoryDto,
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Dashboards/category',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns DashboardCategoryDto
   * @throws ApiError
   */
  public static dashboardsGetDashboardCategories(): CancelablePromise<Array<DashboardCategoryDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Dashboards/dashboard-categories',
    });
  }

  /**
   * @param requestBody
   * @returns binary
   * @throws ApiError
   */
  public static dashboardsAssignDashboardCategory(
    requestBody: AssignDashboardCategory,
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Dashboards/assign-dashboard-category',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param id
   * @returns binary
   * @throws ApiError
   */
  public static dashboardsDeleteDashboardCategory(
    id: string,
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Dashboards/dashboard-category/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param requestBody
   * @returns binary
   * @throws ApiError
   */
  public static dashboardsRemoveRoleFromDashboard(
    requestBody: DashboardRoleDto,
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Dashboards/remove-role',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param roleId
   * @param requestBody
   * @returns binary
   * @throws ApiError
   */
  public static dashboardsUpdateRoleDashboards(
    roleId: string | null,
    requestBody: DashboardRoleUpdateRecord,
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Dashboards/roles/{roleId}/dashboards',
      path: {
        'roleId': roleId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Dashboard
   * @throws ApiError
   */
  public static dashboardGetByKey(
    dashboardKey: string
  ): CancelablePromise<Dashboard> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Dashboards/{key}',
      path: {
        'key': dashboardKey
      },
    });
  }
}
