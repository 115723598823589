import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { MESSAGES_TAG, SUGGESTIONS_TAG } from '../../constants/tags';

const mainApi = createApi({
  reducerPath: 'mainApi',
  tagTypes: [MESSAGES_TAG, SUGGESTIONS_TAG],
  baseQuery: fetchBaseQuery({
    baseUrl: window.origin,
    prepareHeaders: (headers) => {
      const storedToken = localStorage.getItem('kt-auth-react-v')
      const {api_token} = storedToken && JSON.parse(storedToken)

      if (api_token) {
        headers.set('Authorization', `Bearer ${api_token}`)
      }

      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');

      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default mainApi;
