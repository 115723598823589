import {Route, Routes} from 'react-router-dom'
import {UserList} from './Users/pages/userList'
import {PrimeReactProvider} from 'primereact/api'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import 'primeicons/primeicons.css'
import {RolesList} from './Roles/pages/rolesList'
import {PythiaConfiguration} from './PythiaV2/pages/pythiaConfiguration'

export const NewConfiguration = () => {
  return (
    <div style={{margin: '5rem'}}>
      <PrimeReactProvider>
        <Routes>
          <Route path='/users/*' element={<UserList />} />
          <Route path='/roles' element={<RolesList />} />
          <Route path='/pythia-configuration/*' element={<PythiaConfiguration />} />
        </Routes>
      </PrimeReactProvider>
    </div>
  )
}
