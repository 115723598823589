export const CUSTOM_RANGE_ID = 7;
export const DEFAULT_RANGE = 7;
export const LAST_WEEK = 'Last week';
export const LAST_MONTH = 'Last Month';
export const MONTH_COUNT = 1;
export const LAST_THREE_MONTH = 'Last 3 months';
export const TREE_MONTH_COUNT = 3;
export const LAST_SIX_MONTH = 'Last 6 months';
export const SIX_MONTH_COUNT = 6;
export const LAST_NINE_MONTH = 'Last 9 months';
export const NINE_MONTH_COUNT = 9;
export const LAST_YEAR = 'Last Year';
export const YEAR_COUNT = 12;
export const DAYS_PERIOD = 'days';
export const MONTHS_PERIOD = 'months';
export const WEEK_STARTS_ON = 1;
export const NUMBER_OF_MONTHS = 13;
