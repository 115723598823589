import {useExternalSoftwares} from '../../../queries/getExternalSoftwares'
import {ExternalSoftwareDto} from '../../../api/models/ExternalSoftwareDto'

type SoftwareBoxProps = {
  software: ExternalSoftwareDto
}

function SoftwareBox({software}: SoftwareBoxProps) {

  return (
    <div
      style={{
        flex: '1 0 20%',
        border: '1px solid, #ccc',
        padding: '10px',
        boxSizing: 'border-box',
        textAlign: 'center',
        cursor: 'pointer',
      }}
      onClick={() => window.open(software.softwareUrl, '_blank')}
    >
      <div style={{fontWeight: 'bold'}}>{software.softwareName}</div>
      <img
        style={{
          margin: '10px 0',
          maxHeight: '200px',
          maxWidth: '300px',
          backgroundColor: '#f9f9f9',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        src={`data:image/jpeg;base64,${software.icon}`}
        alt={`${software.softwareName} preview`}
      />
    </div>
  )
}

export const SoftwareList = () => {
  const softwareList = useExternalSoftwares()
  return (
    <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
      {softwareList.isSuccess &&
        Array.isArray(softwareList.data) &&
        softwareList.data.map((software, index) => <SoftwareBox key={index} software={software} />)}
    </div>
  )
}

export default SoftwareList
