import type { ReactElement } from 'react';
import type { IThumb } from './interfaces/IThumb';

import { ReactComponent as ThumbDownIcon } from '../../assets/icons/thumb_down.svg';
import { ReactComponent as ThumbUpIcon } from '../../assets/icons/thumb_up.svg';

export default function Thumb({ className, isUp, onClick }: IThumb): ReactElement {
  return (
    <div
      role="presentation"
      onClick={onClick}
      className={className}
    >
      {isUp ? (<ThumbUpIcon />) : (<ThumbDownIcon />)}
    </div>
  );
}
