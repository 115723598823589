import {useMutation} from '@tanstack/react-query'
import {ContentItemService} from '../../../../../../api'
import {queryClient} from '../../../../../../libs/react-query'

export const useAssignRoleToCategory = () =>
  useMutation({
    mutationFn: ContentItemService.contentItemAssignRolesToCategory,
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['categoryRoles']}),
  })

export const useUnAssignRoleToCategory = () =>
  useMutation({
    mutationFn: ContentItemService.contentItemUnAssignRolesToCategory,
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['categoryRoles']}),
  })
