import React from 'react';
import { useTranslation } from 'react-i18next';

import Suggestions from '../Suggestions/Suggestions';
import TextField from '../TextField/TextField';

import type { ReactElement } from 'react';
import type { IInputBlock } from './interfaces/IInputBlock';

import styles from './sass/InputBlock.module.scss';

function InputBlock({
  searchTerm, setSearchTerm, handleAddMessage, isMessageLoading,
}: IInputBlock): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.inputBlock}>
      <div className={styles.inputContainer}>
        <TextField
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleAddMessage={handleAddMessage}
          isMessageLoading={isMessageLoading}
        />
        <Suggestions />
      </div>
    </div>
  );
}

export default InputBlock;
