import {Route, Routes} from 'react-router-dom'
import {UserDetails} from './userDetails'
import {ManageRolesView} from './manageRoles'
import {ResetPasswordView} from './resetPasswordView'
import {CreateUserView} from './createUserView'
import {EditUserView} from './editUserView'

type Props = {
  userId: string
}

export const SidebarContent = ({userId}: Props) => {
  return (
    <Routes>
      <Route path=':userId' element={<UserDetails />} />
      <Route path=':userId/manageRoles' element={<ManageRolesView />} />
      <Route path=':userId/resetPassword' element={<ResetPasswordView />} />
      <Route path=':userId/edit' element={<EditUserView />} />
      <Route path='new' element={<CreateUserView />} />
    </Routes>
  )
}
