import {Column} from 'primereact/column'
import {TreeTable, TreeTableEvent} from 'primereact/treetable'
import {useCategories} from '../api/getCategories'
import {useEffect} from 'react'
import {CreateAskMeCategoryButton} from '../elements/createCategoryButton'
import ResizableSidebar from 'src/app/components/sidebar'
import {useNavigate, useParams} from 'react-router-dom'
import {SidebarContent} from '../elements/sidebarContent'

export const AskMeConfiguration = () => {
  const categories = useCategories()
  const params = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    console.log(categories.data)
  }, [categories.data])

  const handleClose = () => {
    navigate('./')
  }

  const handleRowClick = (e: TreeTableEvent) => {
    if (e.node.data.parentCategory) {
      navigate(`subcategory/${e.node.id}`)
      return
    }
    navigate(`category/${e.node.id}`)
  }

  return (
    <>
      <div style={{margin: '5rem'}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h2>AskMe Configuration</h2>
          <CreateAskMeCategoryButton />
        </div>
        <TreeTable value={categories.data ?? []} onRowClick={handleRowClick}>
          <Column field='name' header='Name' expander />
        </TreeTable>
      </div>
      <ResizableSidebar
        storageKey='askMeConfigurationSidebar'
        visible={!!params['*']}
        onHide={handleClose}
        position='right'
        // style={{width: '70rem'}}
      >
        <SidebarContent />
      </ResizableSidebar>
    </>
  )
}
