import {Avatar} from 'primereact/avatar'
import {Skeleton} from 'primereact/skeleton'
import {useNavigate, useParams} from 'react-router-dom'
import {useContentItemType} from '../../Pythia/queries/getType'
import {Divider} from 'primereact/divider'
import {RolesView} from './types/rolesView'
import {TabMenu, TabMenuTabChangeEvent} from 'primereact/tabmenu'
import {ManageSubCategories} from './types/manageSubCategories'
import {ManageActionCategories} from './types/manageActionCategories'
import {useState} from 'react'
import {Button} from 'primereact/button'
import {useDeleteContentItemType} from '../../Pythia/queries/deleteType'
import {InputText} from 'primereact/inputtext'
import {set} from 'lodash'
import {useUpdateType} from '../../Pythia/queries/updateType'

const items = [
  {label: 'Roles', icon: 'pi pi-users'},
  {label: 'Manage Sub Categories', icon: 'pi pi-wrench'},
  {label: 'Manage Action Categories', icon: 'pi pi-comment'},
]

const viewMap: {[key: number]: JSX.Element} = {
  0: <RolesView />,
  1: <ManageSubCategories />,
  2: <ManageActionCategories />,
}

export const TypesView = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const deleteType = useDeleteContentItemType()
  const {typeId} = useParams()
  const navigate = useNavigate()
  const [isEditing, setIsEditing] = useState(false)
  const updateType = useUpdateType()
  const type = useContentItemType(typeId)
  const [typeName, setTypeName] = useState(type.data?.name ?? '')

  const subCategoryLabel = `${type.data?.name?.charAt(0).toUpperCase()}${type.data?.name
    ?.charAt(1)
    .toUpperCase()}`
  const handleTabChange = (e: TabMenuTabChangeEvent) => {
    setActiveIndex(e.index)
  }
  const handleDeleteClick = () => {
    deleteType.mutate(typeId ?? '', {
      onSuccess: () => {
        navigate('../')
      },
    })
  }
  const handleTypeNameChange = (e: any) => {
    setTypeName(e.target.value)
  }

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }

  const handleUpdateClick = () => {
    updateType.mutate({id: typeId ?? '', name: typeName}, {onSuccess: () => setIsEditing(false)})
  }
  return (
    <div style={{padding: '2rem'}}>
      {type.isLoading ? (
        <>
          <Skeleton shape='circle' size='5rem'></Skeleton>
          <Skeleton className='mb-2'></Skeleton>
          <Skeleton width='10rem' className='mb-2'></Skeleton>
          <Skeleton width='5rem' className='mb-2'></Skeleton>
          <Skeleton height='2rem' className='mb-2'></Skeleton>
          <Skeleton width='10rem' height='4rem'></Skeleton>
        </>
      ) : (
        <>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex'}}>
              <div style={{margin: '4px 20px 0px 0px'}}>
                <Avatar label={subCategoryLabel} shape='circle' size='xlarge' />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                {isEditing ? (
                  <div style={{display: 'flex'}}>
                    <InputText value={typeName} onChange={handleTypeNameChange} />
                    <Button
                      icon='pi pi-check'
                      text
                      severity='info'
                      size='large'
                      onClick={handleUpdateClick}
                      disabled={updateType.isPending}
                    />
                  </div>
                ) : (
                  <h3 style={{paddingLeft: '16.250px'}}>{typeName}</h3>
                )}
              </div>
            </div>
            <div style={{display: 'flex'}}>
              <Button
                icon='pi pi-trash'
                text
                severity='danger'
                size='large'
                onClick={handleDeleteClick}
                disabled={deleteType.isPending}
              />
              <Button
                icon='pi pi-pencil'
                text
                severity='info'
                size='large'
                onClick={handleEditClick}
                disabled={updateType.isPending}
              />
            </div>
          </div>

          <div
            style={{
              marginTop: '2rem',
              width: '100%',
            }}
          >
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={handleTabChange} />
            <div style={{padding: '1rem'}}>{viewMap[activeIndex]}</div>
          </div>
        </>
      )}
    </div>
  )
}
