import React, {PropsWithChildren, useState} from 'react'
import {LoaderContext} from './useLoader'
import styles from './LoaderProvider.module.scss'
import {Button, Spinner} from 'react-bootstrap'

const LoaderProvider = ({children}: PropsWithChildren<any>) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<string | undefined>(undefined)

  const value = {
    loading,
    setLoading: (loading: boolean, message?: string) => {
      setLoading(loading)
      setMessage(message)
    },
    message,
  }

  return (
    <LoaderContext.Provider value={value}>
      {loading && (
        <div className={styles.loaderContainer}>
          <div className={styles.loaderBox}>
            {/*<i className='fas fa-circle-notch fa-spin'></i>*/}
            <Button variant='secondary' disabled>
              <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
              <span className='visually-hidden'>Loading...</span>
            </Button>
            {message && (
              <>
                <Button variant='secondary' disabled>
                  <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                  {message}
                </Button>
              </>
            )}
          </div>
        </div>
      )}
      {children}
    </LoaderContext.Provider>
  )
}

export default LoaderProvider
