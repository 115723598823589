import {TreeTable} from 'primereact/treetable'
import {useContentItemTypes} from '../../Pythia/queries/getTypes'
import {Column} from 'primereact/column'
import {Card} from 'primereact/card'
import {SidebarContent} from '../elements/sidebarContent'
import {useNavigate, useParams} from 'react-router-dom'
import ResizableSidebar from '../../../../components/sidebar'
import {CreateTypeButton} from '../elements/types/createType'
import {useDashboard} from '../../../../common/queries/getDashboard'
import {useEffect, useState} from 'react'
import {DashboardSelect} from './DashboardSelect'
import {useDispatch} from 'react-redux'
import {setDashboardState} from '../../../../common/store/slices/dashboard/slice'

export const PythiaConfiguration = () => {
  const [key, setKey] = useState<string>('')
  const {data: dashboard} = useDashboard(key)
  const types = useContentItemTypes(dashboard?.id)
  const navigate = useNavigate()
  const params = useParams()

  const handleRowClick = (e: any) => {
    if (e.node.data.type) navigate(`${e.node.data.type}/${e.node.id}`)
  }

  const handleClose = () => {
    navigate('./')
  }

  return (
    <div style={{margin: '5rem'}}>
      <Card>
        <div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h2>Pythia Configuration</h2>
            <CreateTypeButton dashboardId={key} />
          </div>
          <div style={{marginTop: 10, marginBottom: 20}}>
            <DashboardSelect onSelect={setKey} />
          </div>
          <TreeTable
            selectionMode='single'
            value={types.data ?? []}
            loading={types.isLoading}
            onRowClick={handleRowClick}
          >
            <Column field='name' header='Name' expander />
            <Column field='description' header='Description' />
          </TreeTable>
        </div>
        <ResizableSidebar
          storageKey='pythiaConfigurationSidebar'
          visible={!!params['*']}
          onHide={handleClose}
          position='right'
          style={{width: '70rem'}}
        >
          <SidebarContent />
        </ResizableSidebar>
      </Card>
    </div>
  )
}
