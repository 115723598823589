import {useQuery} from '@tanstack/react-query'
import {AnalysisOutcomesService, ProcessingStatus} from '../../../../../../api'

const getAnalysisOutcome = async (outcomeId: string) => {
  return await AnalysisOutcomesService.analysisOutcomesGetAnalysisOutcome(outcomeId)
}

export const useGetAnalysisOutcomeQuery = (outcomeId?: string) => {
  return useQuery({
    queryKey: ['getAnalysisOutcome', outcomeId],
    queryFn: () => getAnalysisOutcome(outcomeId!),
    enabled: !!outcomeId,
    refetchInterval: (query) =>
      query.state.data?.processingStatus === ProcessingStatus._0 ? 5000 : false,
  })
}
