import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';

const suggestions = (state: RootState) => state.suggestions;

const suggestionsSelector = createSelector(
  [suggestions],
  ({ page, searchTerm }) => ({ page, searchTerm }),
);

export default suggestionsSelector;
