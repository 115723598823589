import {useMutation} from '@tanstack/react-query'
import {IdentityService} from '../api'

type ResetPasswordOptions = {
  password: string
  id: string
}

const resetPassword = async ({id, password}: ResetPasswordOptions) => {
  return await IdentityService.identityResetUserPassword(id, {password})
}

export const useResetPassword = () =>
  useMutation({
    mutationFn: resetPassword,
  })
