import {useMutation} from '@tanstack/react-query'
import {CreateSuggestionDto, SuggestionsService} from 'src/api'
import {queryClient} from 'src/libs/react-query'

const addSuggestion = async (request: CreateSuggestionDto) => {
  return await SuggestionsService.suggestionsCreateSuggestion(request)
}

export const useAddSuggestion = () => {
  return useMutation({
    mutationFn: addSuggestion,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['askMeSuggestions']})
    },
  })
}
