// import './i18n'
import '../../common/i18n'

import {type ReactElement} from 'react'
import {Route, Routes} from 'react-router-dom'

import Page from '../../components/layout/Page/Page'
import styles from './styles/global.module.scss'
import {setDashboardState} from '../../common/store/slices/dashboard/slice'
import {useDispatch} from 'react-redux'
import AssistTemplatesView from './pages/AssistContent/AssistTemplatesView'
import AssistFilesView from './pages/AssistFilesView/AssistFilesView'

function App(): ReactElement {
  const dispatch = useDispatch()
  const newDashboardState = {
    key: 'assist',
    name: 'Aged Care Assist',
  }
  dispatch(setDashboardState(newDashboardState))

  return (
    <div className={styles.assistBody}>
      <Routes>
        <Route path='/'>
          <Route element={<Page />}>
            <Route
              path='type/:typeId/status/:statusId/*'
              element={<AssistTemplatesView  />}
            />
            <Route path='my-files' element={<AssistFilesView  />} />
            <Route index element={<AssistTemplatesView  />} />
          </Route>
        </Route>
      </Routes>
    </div>
  )
}

export default App
