import {useMutation} from '@tanstack/react-query'
import {ContentItemService} from '../../../../../api'
import {queryClient} from '../../../../../libs/react-query'

export const useCreateTypeCategory = () =>
  useMutation({
    mutationFn: ContentItemService.contentItemCreate3,
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({queryKey: ['analysisCategories']})
      queryClient.invalidateQueries({queryKey: ['contentItemTypes', request.contentItemTypeId]})
    },
  })
