import {Card} from 'primereact/card'
import {Column} from 'primereact/column'
import {DataTable, DataTableStateEvent} from 'primereact/datatable'
import {UserQueryOptions, useUsers} from '../../../../../queries/getUsers'
import {ChangeEvent, useState} from 'react'
import {IconField} from 'primereact/iconfield'
import {InputIcon} from 'primereact/inputicon'
import {InputText} from 'primereact/inputtext'
import styles from './userList.styles.module.scss'
import {useNavigate, useParams} from 'react-router-dom'
import {SidebarContent} from '../elements/sidebarContent'
import {Button} from 'primereact/button'
import ResizableSidebar from '../../../../components/sidebar'

const sortFieldMap: {[key: string]: string} = {
  username: 'UserName',
  email: 'Email',
  firstName: 'FirstName',
  lastName: 'LastName',
}

const sortFieldReverseMap: {[key: string]: string} = {
  UserName: 'username',
  Email: 'email',
  FirstName: 'firstName',
  LastName: 'lastName',
}

export const UserList = () => {
  const [filters, setFilters] = useState<UserQueryOptions>({
    page: 1,
    pageSize: 50,
  })
  const params = useParams()
  const users = useUsers(filters)
  const navigate = useNavigate()
  const handlePageChange = (e: DataTableStateEvent) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: (e.page ?? 0) + 1,
      pageSize: e.rows,
    }))
  }

  const handleSortChange = (e: DataTableStateEvent) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: sortFieldMap[e.sortField],
      sortDescending: e.sortOrder === -1,
    }))
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: e.target.value,
    }))
  }

  const handleRowClick = (e: any) => {
    navigate(e.data.id)
  }

  const handleClose = () => {
    navigate('./')
  }

  return (
    <div style={{margin: '5rem'}}>
      <Card>
        <div className={styles.search}>
          <IconField iconPosition='left' onChange={handleSearch}>
            <InputIcon className='pi pi-search'> </InputIcon>
            <InputText v-model='value1' placeholder='Search' />
          </IconField>
          <Button label='Add User' icon='pi pi-plus' onClick={() => navigate('new')} />
        </div>
        <DataTable
          dataKey='id'
          loading={users.isLoading}
          tableStyle={{minWidth: '50rem'}}
          value={users.data?.users}
          paginator
          rows={filters.pageSize}
          onPage={handlePageChange}
          onSort={handleSortChange}
          removableSort
          sortField={(filters.sortBy && sortFieldReverseMap[filters.sortBy]) ?? ''}
          filterDisplay='row'
          onRowClick={handleRowClick}
          selectionMode='single'
        >
          <Column field={'username'} header='Username' sortable />
          <Column field={'email'} header='Email' sortable />
          <Column field={'firstName'} header='First Name' sortable />
          <Column field={'lastName'} header='Last Name' sortable />
          <Column field={'phone'} header='Phone' />
        </DataTable>

        <ResizableSidebar
          storageKey='users-sidebar'
          visible={!!params['*']}
          onHide={handleClose}
          position='right'
          style={{width: '50rem'}}
        >
          <SidebarContent userId={params['*'] ?? ''} />
        </ResizableSidebar>
      </Card>
    </div>
  )
}
