import {useMutation} from '@tanstack/react-query'

import {AnalysisFeedback, AnalysisOutcomeService} from '../../../../../../../../api'
import {queryClient} from '../../../../../../../../libs/react-query'

type SetAnalysisFeedbackOptions = {
  id: string
  feedback: AnalysisFeedback
  comment?: string
}

export const useSetAnalysisFeedbackMutation = () =>
  useMutation({
    mutationFn: ({id, feedback, comment}: SetAnalysisFeedbackOptions) =>
      AnalysisOutcomeService.analysisOutcomeAddFeedback(id, feedback, comment),
    onSuccess: (_, request) =>
      queryClient.invalidateQueries({
        queryKey: ['analysisOutcomeGetFeedback', request.id],
      }),
  })
