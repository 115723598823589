import React, {useState, useEffect} from 'react'
import {Sidebar, SidebarProps} from 'primereact/sidebar'

type ResizableSidebarProps = SidebarProps & {
  storageKey: string
}

const ResizableSidebar = ({children, storageKey, ...props}: ResizableSidebarProps) => {
  const prefixedStorageKey = `sidebar-${storageKey}`
  const [sidebarWidth, setSidebarWidth] = useState(
    localStorage.getItem(prefixedStorageKey) || '50rem'
  )

  useEffect(() => {
    localStorage.setItem(prefixedStorageKey, sidebarWidth)
  }, [sidebarWidth, prefixedStorageKey])

  const handleMouseDown = (e: any) => {
    e.preventDefault()
    const initialPos = e.clientX
    const handleMouseMove = (e: any) => {
      const newWidth = parseFloat(sidebarWidth) + (initialPos - e.clientX)
      setSidebarWidth(`${newWidth}px`)
    }
    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  return (
    <Sidebar {...props} style={{width: sidebarWidth}}>
      <div
        style={{
          cursor: 'ew-resize',
          width: '1rem',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
        }}
        onMouseDown={handleMouseDown}
      />
      <div style={{width: sidebarWidth, height: '100%'}}>{children}</div>
    </Sidebar>
  )
}

export default ResizableSidebar
