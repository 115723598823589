import type {ReactElement} from 'react'
import type IButton from './interfaces/IButton'

import styles from './sass/Button.module.scss'
import DotsLoader from '../../../../components/DotsLoader/DotsLoader'
import ServiceItem from '../../../AgedCareAssist/pages/AiActionsSidebarView/AiActionsList/AiRequestItem/AiRequestItem'
import {noop} from 'lodash'

export default function Button({
  title,
  variant,
  icon,
  widthVariant,
  onClick,
  isLoading,
  disabled,
}: IButton): ReactElement {
  return (
    <div
      className={`
    ${styles.button} 
    ${variant ? styles[variant] : ''}
    ${widthVariant ? styles[widthVariant] : ''}
    ${disabled ? styles.disabled : ''}
    `}
      onClick={onClick}
      role='button'
      tabIndex={0}
      onKeyDown={onClick}
    >
      {icon ? <img src={icon} alt='icon' /> : null}
      {isLoading ? <DotsLoader /> : <span className={styles.title}>{title}</span>}
    </div>
  )
}
