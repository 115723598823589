import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';

import type { ReactElement } from 'react';
import type { ISelect } from '../interfaces/ISelect';

import styles from '../sass/SelectField.module.scss';

export default function SelectField({
  label, value, data, placeholder, control, errors,
}: ISelect): ReactElement {
  const options = useMemo(() => data.map((item) => ({
    label: item.name,
    value: item.name,
    id: item.id,
  })), [data]);

  return (
    <div className={styles.selectField}>
      <div className={styles.label}>{label}</div>
      <Controller
        name={value}
        control={control}
        rules={{ required: true }}
        render={({ field: { value: fieldValue, onChange, onBlur } }) => (
          <Select
            closeMenuOnSelect
            classNamePrefix="react-select"
            options={options}
            value={fieldValue}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            className={styles.select}
            theme={(theme) => ({
              ...theme,
              borderRadius: 10,
              colors: {
                ...theme.colors,
                primary25: 'rgba(73,98,168, 0.3)',
                primary: 'rgba(73,98,168)',
              },
            })}
          />
        )}
      />
      {errors ? <ErrorMessage error={errors} /> : null}
    </div>
  );
}
