import {createSlice} from '@reduxjs/toolkit';

import DashboardState from "./interfaces/DashboardState";

export const initialState: DashboardState = {
  key: "",
  name: "",
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardState: (state, action) => {
      return {
        ...
          state,
        ...
          action.payload
      }
    },
  },
});

export const {setDashboardState} = dashboardSlice.actions;

export default dashboardSlice.reducer;
