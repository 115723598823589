import {useMutation} from '@tanstack/react-query'
import {ContentItemService} from '../../../../../api'
import {queryClient} from '../../../../../libs/react-query'

export const useAssignPromptToCategory = () =>
  useMutation({
    mutationFn: ContentItemService.contentItemAssignPromptToCategory,
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({queryKey: ['analysisCategories', request.categoryId]})
      queryClient.invalidateQueries({queryKey: ['pythia']})
    },
  })

export const useUnAssignPromptToCategory = () =>
  useMutation({
    mutationFn: ContentItemService.contentItemUnAssignPromptToCategory,
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({queryKey: ['analysisCategories', request.categoryId]})
      queryClient.invalidateQueries({queryKey: ['pythia']})
    },
  })
