import {useMutation} from '@tanstack/react-query'
import {queryClient} from '../libs/react-query'
import {AccountService} from '../api'

export const useDeleteUser = () =>
  useMutation({
    mutationFn: AccountService.accountDeleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['users']})
    },
  })
