export default {
  typeYourMessage: 'Type your message',
  lastGenerationDate: 'Last generation date: ',
  you: 'You',
  disclaimer: 'DISCLAIMER: These results may contain confidential information. AgedCareAI disclaims any and all '
    + 'guarantees, undertakings and warranties, expressed or implied, and is not liable for any loss or damage '
    + 'whatsoever (including human and computer error, negligent or otherwise, or incidental or consequential '
    + 'loss or damage) arising out of, or in connection with, any use or reliance on the information contained '
    + 'within or arising from the result. The recipient accepts sole responsibility, irrespective of the purpose '
    + 'for which such use or results are applied. Any results produced by AgedCareAI may contain automated errors, '
    + 'inconsistencies, or inaccuracies.',
};
