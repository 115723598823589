import Thumb from '../../../../components/Thumb/Thumb'
import {useGetAnalysisFeedbackQuery} from './api/useGetAnalysisFeedbackQuery'
import {useSetAnalysisFeedbackMutation} from './api/useSetAnalysisFeedbackMutation'

import {type ReactElement, useState} from 'react'
import type {IStandardsIconsBlock} from './interfaces/IStandardsIconsBlock'

import copyIcon from 'src/app/common/assets/icons/copy.svg'
import reload from 'src/app/common/assets/icons/reload_arrow.svg'

import styles from './sass/StandardsIconsBlock.module.scss'

import {FeedbackModal} from './elements/FeedbackModal'
import {useCurrentUser} from '../../../../../../common/queries/getCurrentUser'

export default function StandardsIconsBlock({
  onCopy,
  onRegenerate,
  analysisId,
  mailLink,
}: IStandardsIconsBlock): ReactElement {
  const analysisFeedback = useGetAnalysisFeedbackQuery(analysisId)
  const user = useCurrentUser()
  const setFeedback = useSetAnalysisFeedbackMutation()
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const handleNegativeFeedback = () => {
    if (analysisFeedback.data === -1) {
      setFeedback.mutate({
        id: analysisId,
        feedback: 0,
      })
      return
    }
    setShowFeedbackModal(true)
  }
  return (
    <div className={styles.icons}>
      <button type='button' className={styles.icon} onClick={onCopy} onKeyDown={onCopy}>
        <img src={copyIcon} alt='copy' />
      </button>
      <Thumb
        isUp
        className={`${styles.icon} ${styles.thumb} ${
          analysisFeedback.data === 1 ? styles.activeIcon : ''
        }`}
        onClick={() => {
          setFeedback.mutate({
            id: analysisId,
            feedback: analysisFeedback.data === 1 ? 0 : 1,
          })
        }}
      />
      <Thumb
        className={`${styles.icon} ${styles.thumb} ${
          analysisFeedback.data === -1 ? styles.activeIcon : ''
        }`}
        onClick={() => handleNegativeFeedback()}
      />
      {/*<a className={styles.icon} href={mailLink} target='_blank'>*/}
      {/*  <i className='pi pi-envelope' style={{color: '#98a4b6', fontSize: '18px'}}></i>*/}
      {/*</a>*/}
      {user.isSuccess && user.data?.roles?.includes('Admin') && (
        <button
          type='button'
          className={styles.icon}
          onClick={onRegenerate}
          onKeyDown={onRegenerate}
        >
          <img src={reload} alt='reload' className={styles.icon} />
        </button>
      )}
      <FeedbackModal
        id={analysisId}
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
      />
    </div>
  )
}
