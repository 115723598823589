import type {KeyboardEvent, ReactElement} from 'react'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {TEXTAREA_INITIAL_HEIGHT} from '../../../../constants/chat'
import useDebounce from '../../../../hooks/useDebounce'
import {useAppSelector} from '../../../../store/hooks/useApp'
import categoriesSelector from '../../../../store/slices/categories/selectors'
import {useFetchSuggestionsQuery} from '../../../../store/slices/suggestions/apis/suggestions'
import Button from '../Button/Button'
import type {ITextField} from './interfaces/ITextField'

import {ReactComponent as Arrow} from '../../../../assets/icons/chat/up_arrow.svg'

import styles from './sass/TextField.module.scss'
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
} from 'primereact/autocomplete'

export default function TextField({
  searchTerm,
  setSearchTerm,
  handleAddMessage,
  isMessageLoading,
}: ITextField): ReactElement {
  const {t} = useTranslation()
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const debouncedSearchTerm = useDebounce(searchTerm.trim())
  const {subCategory} = useAppSelector(categoriesSelector)
  const [overlayVisible, setOverlayVisible] = useState(false)

  const {data} = useFetchSuggestionsQuery({
    subCategory: subCategory?.id || '',
    search: debouncedSearchTerm,
    pageNumber: 1,
    pageSize: 10,
  })
  const [suggestions, setSuggestions] = useState<string[]>([])

  useEffect(() => {
    if (searchTerm && data) {
      const values = data.data.map((x) => x.text)
      setSuggestions(values)
    } else {
      setSuggestions([])
    }
  }, [data, searchTerm])

  const onHandleSubmit = async () => {
    const element = document.getElementById('message-0')
    await handleAddMessage()

    if (textareaRef.current) {
      textareaRef.current.style.height = TEXTAREA_INITIAL_HEIGHT
    }

    if (element) {
      const container = element.parentElement
      if (container) {
        container.scrollTop = 0
      }
    }
  }

  const onHandleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (overlayVisible) {
      return
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      if (!isMessageLoading) {
        e.preventDefault()
        onHandleSubmit().catch(() => {})
      }
    }

    if (e.key === 'Tab' && suggestions) {
      e.preventDefault()
      setSearchTerm(suggestions[0])
    }
  }

  const onHandleChange = (e: AutoCompleteChangeEvent) => {
    setSearchTerm(e.value)
  }

  const onHandleComplete = (e: AutoCompleteCompleteEvent) => {
    setSearchTerm(e.query)
  }

  return (
    <div className={styles.searchInputBlock}>
      <div className={styles.searchInput}>
        <div className={styles.inputField}>
          <AutoComplete
            value={searchTerm}
            onChange={onHandleChange}
            className={styles.autoComplete}
            onKeyDown={onHandleKeyDown}
            aria-label='Type your message'
            suggestions={suggestions}
            placeholder={t('chat.typeYourMessage')}
            completeMethod={onHandleComplete}
            onShow={() => setOverlayVisible(true)}
            onHide={() => setOverlayVisible(false)}
          />
        </div>
        <Button
          icon={<Arrow className={styles.arrow} />}
          onClick={onHandleSubmit}
          buttonStyle={styles.sendButton}
          disabled={!searchTerm && isMessageLoading}
        />
      </div>
    </div>
  )
}
