export default function removeHtmlTags(html: string) {
  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  // return str.replace(/<[^>]*>/g, '')

  const tempElement = document.createElement('div')
  tempElement.innerHTML = html
  return tempElement.textContent || tempElement.innerText || ''
}
