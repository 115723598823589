import '../../common/i18n'

import {type ReactElement, useEffect} from 'react'
import {Route, Routes} from 'react-router-dom'

import Complaints from './pages/Complaints/Complaints'

import Page from '../../components/layout/Page/Page'
import styles from './styles/global.module.scss'
import {useDispatch} from "react-redux";
import {setDashboardState} from 'src/app/common/store/slices/dashboard/slice';
import {useAppDispatch} from "./store/hooks/useApp";

function App(): ReactElement {
  let dispatch = useDispatch();
  const newDashboardState = {
    key: 'agedcareai',
    name: 'Aged Care AI',
  };
  dispatch(setDashboardState(newDashboardState));
  
  // useEffect(() => {
  //   function receiveMessage(event: {
  //     data: { type?: any; token?: any; parentUrl?: any };
  //   }) {
  //     if (event.data.type === 'INIT_DATA') {
  //       const { token, parentUrl } = event.data;
  //       // Example: Setting up Axios for API calls
  //       axios.defaults.baseURL = parentUrl;
  //       localStorage.setItem('kt-auth-react-v', token);
  //       localStorage.setItem('parentUrl', parentUrl);
  //     }
  //   }
  //   window.addEventListener('message', receiveMessage);
  //   return () => window.removeEventListener('message', receiveMessage);
  // }, []);

  return (
    <div className={styles.pythiaBody}>
      <Routes>
        <Route path='/'>
          <Route element={<Page/>}>
            <Route path='type/:typeId/status/:statusId/*' element={<Complaints/>}/>
            <Route index element={<Complaints/>}/>
          </Route>
        </Route>
      </Routes>
    </div>
  )
}

export default App
