import React, {ReactElement, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import DotsLoader from '../../../../components/DotsLoader/DotsLoader'
import useDebounce from '../../../../common/hooks/useDebounce'
import styles from './AssistTemplatesView.module.scss'
import Title from '../../../PythiaV3/components/Title/Title'
import {useStatus} from '../../../../common/queries/getStatus'
import SearchInputBlock from '../../../PythiaV3/pages/Complaints/components/SearchInputBlock/SearchInputBlock'
import {IContentItem} from '../../../../common/store/slices/contentItems/interfaces/IContentItemsResponse'
import {useFetchContentItemsQuery} from '../../../../common/store/slices/contentItems/apis/contentItems'
import {CreateNewItemButton} from '../../../../components/CreateNewItemButton/CreateNewItemButton'
import {useCurrentUser} from '../../../../../queries/getCurrentUser'
import {Button} from 'react-bootstrap'
import {useCreateFileMutation} from '../../store/slices/assistFiles/apis/assistFiles'
import AssistFilesContentView from '../AssistFilesContentView/AssistFilesContentView'
import {formatAndSanitizeContent} from '../../../../common/helpers/formatAndSanitizeContent'

function AssistTemplatesView(): ReactElement {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm.trim())
  const {typeId, statusId} = useParams()
  const {data, isLoading} = useFetchContentItemsQuery({
    statusId: statusId || '',
    typeId: typeId || '',
    searchInput: debouncedSearchTerm,
  })
  const [mappedData, setMappedData] = useState<IContentItem[]>([])
  const [activeItem, setActiveItem] = useState<IContentItem | undefined>(undefined)
  const status = useStatus(statusId)
  const user = useCurrentUser()
  const [createFile, {isLoading: creatingFile}] = useCreateFileMutation()

  const isAdmin = user.data?.roles?.includes('Admin') ?? false

  useEffect(() => {
    const mappedItems: IContentItem[] =
      data?.items.map((item) => ({
        ...item,
        content: formatAndSanitizeContent(item.content),
      })) ?? []
    setMappedData(mappedItems)
    if (activeItem && data?.items?.some((item) => item.id === activeItem.id)) {
      return
    }
    setActiveItem(data?.items[0])
  }, [data])

  useEffect(() => {
    if (activeItem) {
      return
    }
    setActiveItem(data?.items[0])
  }, [data])

  const onClick = async () => {
    await createFile({
      typeId: activeItem?.contentItemTypeId ?? '',
      statusId: activeItem?.contentItemStatusId ?? '',
      subject: activeItem?.subject ?? '',
      content: activeItem?.content ?? '',
    })
  }

  if (isLoading) return <DotsLoader variant='centered' />

  return (
    <div className={styles.container}>
      <div className={styles.pageHeader}>
        <Title text={status.data?.submenuTitle ?? ''} />
        <div className={styles.buttonContainer}>
          <Button
            variant='primary'
            onClick={onClick}
            color='#4962A8'
            style={{backgroundColor: '#4962A8', height: '3rem'}}
            disabled={creatingFile || !activeItem}
          >
            Use Template
          </Button>
          {isAdmin && <CreateNewItemButton />}
        </div>
      </div>
      <div className={styles.content}>
        <SearchInputBlock
          complaints={mappedData}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          activeIncident={activeItem}
          setActiveIncident={(searchItem) => {
            const item = data?.items.find((item) => searchItem.id === item.id)
            setActiveItem(item)
          }}
        />
        {data?.totalCount && data?.totalCount > 0 && (
          <AssistFilesContentView activeItem={activeItem || null} mode='template' />
        )}
      </div>
    </div>
  )
}

export default AssistTemplatesView
