import {Route, Routes} from 'react-router-dom'
import {CategoriesView} from './categoriesView'
import {SubCategoriesView} from './subCategoriesView'
import {TypesView} from './typesView'

export const SidebarContent = () => {
  return (
    <div>
      <Routes>
        <Route path='category/:categoryId' element={<CategoriesView />} />
        <Route path='subcategory/:subCategoryId' element={<SubCategoriesView />} />
        <Route path='type/:typeId' element={<TypesView />} />
      </Routes>
    </div>
  )
}
