import React from 'react'
import {Helmet} from 'react-helmet'

export const GoogleAnalyticsTag = () => (
  <Helmet>
    <script async src='https://www.googletagmanager.com/gtag/js?id=G-FXD29SYEVL'></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-FXD29SYEVL');
      `}
    </script>
  </Helmet>
)
