import './i18n';

import { Route, Routes } from 'react-router-dom';

import { SUGGESTIONS } from './constants/general';
import Page from './layout/Page/Page';
import Chat from './pages/Chat/Chat';
import Suggestion from './pages/Suggestion/Suggestion';
import Suggestions from './pages/Suggestions/Suggestions';

import type { ReactElement } from 'react';

import styles from './styles/global.module.scss'

function App(): ReactElement {
  return (
    <div className={styles.askMeBody}>
      <Routes>
        <Route path="/">
          <Route element={<Page />}>
            <Route index element={<Chat />} />
            <Route path={SUGGESTIONS} element={<Suggestions />} />
            <Route path={`${SUGGESTIONS}/edit/:id`} element={<Suggestion />} />
            <Route path={`${SUGGESTIONS}/create`} element={<Suggestion />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
