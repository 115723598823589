import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';

const chat = (state: RootState) => state.chat;

const chatSelector = createSelector(
  [chat],
  ({
    isModalOpen, skip, message, chatError,
  }) => ({
    isModalOpen, skip, message, chatError,
  }),
);

export default chatSelector;
