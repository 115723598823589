import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type IAuth from './interfaces/IAuth';

export const initialState: IAuth = {
  id: undefined,
  token: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthState: (
      state,
      action: PayloadAction<Partial<IAuth>>,
    ) => ({ ...state, ...action.payload }),
    logout: () => initialState,
  },
});

export const { setAuthState } = userSlice.actions;

export default userSlice.reducer;
