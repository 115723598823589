import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { ICategories } from './interfaces/ICategories';

export const initialState: ICategories = {
  category: null,
  subCategory: null,
  categories: undefined,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategoriesState: (
      state,
      action: PayloadAction<Partial<ICategories>>,
    ) => ({ ...state, ...action.payload }),
  },
});

export const { setCategoriesState } = categoriesSlice.actions;

export default categoriesSlice.reducer;
