/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignRoleToExternalSoftwareCommand } from '../models/AssignRoleToExternalSoftwareCommand';
import type { CreateExternalSoftwareCommand } from '../models/CreateExternalSoftwareCommand';
import type { ExternalSoftware } from '../models/ExternalSoftware';
import type { ExternalSoftwareDto } from '../models/ExternalSoftwareDto';
import type { UnassignRoleFromExternalSoftwareCommand } from '../models/UnassignRoleFromExternalSoftwareCommand';
import type { UpdateExternalSoftwareCommand } from '../models/UpdateExternalSoftwareCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExternalSoftwareService {

    /**
     * @param requestBody 
     * @returns number 
     * @throws ApiError
     */
    public static externalSoftwareCreate(
requestBody: CreateExternalSoftwareCommand,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ExternalSoftware',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ExternalSoftwareDto 
     * @throws ApiError
     */
    public static externalSoftwareGetAll(): CancelablePromise<Array<ExternalSoftwareDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ExternalSoftware',
        });
    }

    /**
     * @param id 
     * @returns ExternalSoftware 
     * @throws ApiError
     */
    public static externalSoftwareGet(
id: string,
): CancelablePromise<ExternalSoftware> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ExternalSoftware/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static externalSoftwareUpdate(
id: string,
requestBody: UpdateExternalSoftwareCommand,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/ExternalSoftware/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns binary 
     * @throws ApiError
     */
    public static externalSoftwareDelete(
id: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/ExternalSoftware/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static externalSoftwareAssignRole(
requestBody: AssignRoleToExternalSoftwareCommand,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ExternalSoftware/assign-role',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static externalSoftwareUnassignRole(
requestBody: UnassignRoleFromExternalSoftwareCommand,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ExternalSoftware/unassign-role',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
