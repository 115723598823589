import React from 'react'
import * as Sentry from '@sentry/browser'
import App from './App'
import {Provider} from 'react-redux'
import {store} from './store/store'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
  })
}

export const AgedCareAssist = () => (
  <Provider store={store}>
    {/*<PersistGate loading={null} persistor={persistor}>*/}
    <App />
    {/*</PersistGate>*/}
  </Provider>
)
