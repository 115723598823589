import { GET } from '../../../../constants/requestMethods';
import { CATEGORIES } from '../../../../constants/requestUrls';
import mainApi from '../../../apis/main';

import { IParentCategory } from '../interfaces/ICategoriesResponse';

const categoriesApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchCategories: builder.query<IParentCategory[], void>({
      query: () => ({
        url: `${CATEGORIES}`,
        method: GET,
      }),
    }),
  }),
});

export const {
  useFetchCategoriesQuery,
} = categoriesApi;

export default categoriesApi;
