import {useParams} from 'react-router-dom'
import {Chip} from 'primereact/chip'
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {useState} from 'react'
import {InputText} from 'primereact/inputtext'
import {useForm} from 'react-hook-form'
import {useCreateStatus} from '../../../Pythia/queries/createStatus'
import {useStatuses} from '../../../Pythia/queries/getStatuses'
import {useDeleteStatus} from '../../../Pythia/queries/deleteStatus'
import {useDeleteCategory} from '../../../Pythia/queries/deleteCategory'
import {useAnalysisCategories} from '../../../Pythia/queries/getCategories'
import {useContentItemType} from '../../../Pythia/queries/getType'
import {useCreateTypeCategory} from '../../../Pythia/queries/createCategory'
import {Divider} from 'primereact/divider'

type FormData = {
  name: string
  description: string
}
export const ManageActionCategories = () => {
  const {typeId} = useParams()
  const type = useContentItemType(typeId)
  const removeActionCategory = useDeleteCategory()
  const [visible, setVisible] = useState(false)
  const form = useForm<FormData>()
  const {register, handleSubmit} = form
  const handleHide = () => setVisible(false)
  const onClick = () => setVisible(true)
  const createSubCategory = useCreateTypeCategory()

  const onSubmit = (data: FormData) => {
    createSubCategory.mutate({...data, contentItemTypeId: typeId}, {onSuccess: handleHide})
  }

  const footerContent = (
    <div>
      <Button
        label='Cancel'
        icon='pi pi-times'
        onClick={() => setVisible(false)}
        className='p-button-text'
      />
      <Button
        label='Submit'
        icon='pi pi-check'
        autoFocus
        type='submit'
        loading={createSubCategory.isPending}
        form={'typeForm'}
      />
    </div>
  )

  const handleRemove = (id: string) => {
    removeActionCategory.mutate(id)
  }
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 60,
        }}
      >
        <h3>Action Categories</h3>
        <Button label='Add Action Category' onClick={onClick} />
      </div>
      <Divider />
      {type.data?.analysisCategories?.map((cat) => (
        <div key={cat.id}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <h5 style={{margin: 0}}>{cat.name}</h5>
            <Button
              icon='pi pi-trash'
              text
              severity='danger'
              size='large'
              onClick={() => handleRemove(cat.id ?? '')}
            />
          </div>
          <Divider />
        </div>
      ))}
      <Dialog
        header='Create Action Category'
        visible={visible}
        style={{width: '30rem'}}
        onHide={handleHide}
        footer={footerContent}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={'typeForm'}>
          <div className='p-fluid'>
            <div className='p-field'>
              <label htmlFor='name'>Name</label>
              <InputText id='name' type='text' {...register('name', {required: true})} />
            </div>
            <div className='p-field'>
              <label htmlFor='description'>Description</label>
              <InputText id='description' type='text' {...register('description')} />
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  )
}
