import {useMutation} from '@tanstack/react-query'
import {SuggestionsService} from 'src/api'
import {queryClient} from 'src/libs/react-query'

const deleteSuggestion = async (id: string) => {
  return await SuggestionsService.suggestionsDeleteSuggestion(id)
}

export const useDeleteSuggestion = () => {
  return useMutation({
    mutationFn: deleteSuggestion,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['askMeSuggestions']})
    },
  })
}
