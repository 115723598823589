import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { IChat } from './interfaces/IChat';

export const initialState: IChat = {
  isModalOpen: false,
  message: '',
  skip: 0,
  chatError: '',
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.isModalOpen = !state.isModalOpen;
    },
    setChatState: (
      state,
      action: PayloadAction<Partial<IChat>>,
    ) => ({ ...state, ...action.payload }),
  },
});

export const { toggleModal, setChatState } = chatSlice.actions;

export default chatSlice.reducer;
