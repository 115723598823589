import {useMutation} from '@tanstack/react-query'
import {AccountService, UserDto} from '../api'
import {queryClient} from '../libs/react-query'

const editUser = async (data: UserDto) => {
  await AccountService.accountUpdateUser(data)
}

export const useUpdateUser = () =>
  useMutation({
    mutationFn: editUser,
    onSettled: () => queryClient.invalidateQueries({queryKey: ['users']}),
  })
