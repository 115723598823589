import {useMutation} from '@tanstack/react-query'
import {AnalysisOutcomesService} from '../../../../api'
import {queryClient} from '../../../../libs/react-query'

const deleteAnalysisOutcome = async (id: string) => {
  return await AnalysisOutcomesService.analysisOutcomesDeleteAnalysisOutcome(id)
}

export const useDeleteAnalysisOutcome = () =>
  useMutation({
    mutationFn: deleteAnalysisOutcome,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['analysisOutcomes']})
      queryClient.invalidateQueries({queryKey: ['getAnalysisOutcome']})
    },
  })
