// ConfirmationModalProvider.tsx
import React, {createContext, useContext, useState, ReactNode, FunctionComponent} from 'react'
import {Button} from 'react-bootstrap'
import Modal from 'react-modal'

Modal.setAppElement('#root')
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px', // You can set the width as you like
    height: '200px', // You can set the height as you like
  },
}
const ConfirmationModalContext = createContext<(callback: () => void) => void>(() => {})

export const ConfirmationModalProvider: FunctionComponent<{children: ReactNode}> = ({children}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [confirmAction, setConfirmAction] = useState<() => void>(() => () => {})

  const openConfirmationModal = (callback: () => void) => {
    setConfirmAction(() => callback)
    setIsModalOpen(true)
  }

  const handleConfirm = () => {
    setIsModalOpen(false)
    confirmAction()
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  return (
    <ConfirmationModalContext.Provider value={openConfirmationModal}>
      {children}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleClose}
        contentLabel='Confirmation Modal'
        style={customStyles}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Are you sure?</h5>
            <button
              type='button'
              className='btn-close'
              onClick={handleClose}
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <p>Do you really want to delete this value?</p>
          </div>
          <div className='modal-footer'>
            <Button onClick={handleClose} style={{marginRight: '1rem'}}>
              Cancel
            </Button>
            <Button onClick={handleConfirm} style={{marginRight: '1rem'}} variant='danger'>
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </ConfirmationModalContext.Provider>
  )
}

export const useConfirmationModal = () => {
  const context = useContext(ConfirmationModalContext)
  if (!context) {
    throw new Error('useConfirmationModal must be used within a ConfirmationModalProvider')
  }
  return context
}
