import type DashboardState from './interfaces/DashboardState';
import {createSelector} from "@reduxjs/toolkit";

export interface RootState {
  dashboard: DashboardState;
}

export const dashboard = (state: RootState) => state.dashboard;

const dashboardSelector = createSelector(
  [dashboard],
  (state) => state,
);

export default dashboardSelector;
