import {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'

import {useFetchCategoriesQuery} from '../../store/slices/categories/apis/categories'
import ArrowButton from '../ArrowButton/ArrowButton'
import NavItem from '../NawItem/NavItem'
import {useAppDispatch, useAppSelector} from '../../store/hooks/useApp'
import {setCategoriesState} from '../../store/slices/categories/slice'
import categoriesSelector from '../../store/slices/categories/selectors'
import book from 'src/app/common/assets/icons/whiteSidebar/admins.svg'
import clinical from 'src/app/common/assets/icons/whiteSidebar/clinical.svg'
import compliance from 'src/app/common/assets/icons/whiteSidebar/compliance.svg'
import feedback from 'src/app/common/assets/icons/whiteSidebar/feedback.svg'
import maintenance from 'src/app/common/assets/icons/whiteSidebar/maintenance.svg'
import marketing from 'src/app/common/assets/icons/whiteSidebar/marketing.svg'
import preAccreditation from 'src/app/common/assets/icons/whiteSidebar/pre_acreditation.svg'
import training from 'src/app/common/assets/icons/whiteSidebar/training.svg'
import type {ReactElement} from 'react'

import shortLogo from '../../assets/images/ai-logo-64.png'
import policies from '../../assets/icons/whiteSidebar/policies.svg'

import styles from './sass/SecondarySidebar.module.scss'
import {useOutsideAlerter} from '../../../../components/layout/SecondarySidebar/hooks/useOutsideAlerter'

const iconMap = [
  book,
  clinical,
  marketing,
  policies,
  preAccreditation,
  compliance,
  maintenance,
  training,
  feedback,
]

export default function SecondarySidebar(): ReactElement {
  const dispatch = useAppDispatch()
  const {categories} = useAppSelector(categoriesSelector)
  const {data} = useFetchCategoriesQuery()
  const [isSidebarOpened, setIsSidebarOpened] = useState(true)
  const [openedItem, setOpenedItem] = useState('')
  const sidebarRef = useRef<HTMLDivElement>(null) // Create a ref for the sidebar
  // Use the custom hook here
  useOutsideAlerter(sidebarRef, () => setIsSidebarOpened(false))

  useEffect(() => {
    const categoriesWithIcons = data?.map((category) => {
      return {...category, icon: policies}
    })
    dispatch(
      setCategoriesState({
        categories: categoriesWithIcons,
      })
    )
  }, [data])

  const onClickHandler = (item: string) => {
    setOpenedItem(item === openedItem ? '' : item)
  }
  const onArrowButtonClick = () => {
    setIsSidebarOpened(!isSidebarOpened)
    setOpenedItem('')
  }

  const onSidebarClick = () => setIsSidebarOpened(true)

  return (
    <div
      ref={sidebarRef}
      className={`${styles.whiteSidebar} ${
        isSidebarOpened ? styles.wideSidebar : styles.shortSidebar
      }`}
    >
      <div className={styles.navigationBrand}>
        <Link to='/'>
          <div className={isSidebarOpened ? styles.logo : styles.shortLogo}>
            {isSidebarOpened ? (
              <h3 className={styles.textLogo}>AskMe</h3>
            ) : (
              <img src={shortLogo} alt='logo' height='48' />
            )}
          </div>
        </Link>
        <ArrowButton onArrowButtonClick={onArrowButtonClick} isSidebarOpened={isSidebarOpened} />
      </div>
      <div className={styles.menuContainer}>
        <nav role='presentation' onClick={onSidebarClick}>
          <ul className={styles.navContainer}>
            {categories?.map(({id, name, subCategories}, index) => (
              <NavItem
                key={id}
                icon={iconMap[index % 9]}
                title={name}
                id={id}
                subCategories={subCategories}
                onClickHandler={onClickHandler}
                openedItem={openedItem}
                isSidebarOpened={isSidebarOpened}
              />
            ))}
          </ul>
        </nav>
        <div className={styles.appName}>
          <h3 style={{color: '#57534f9c'}}>AskMe</h3>
        </div>
      </div>
    </div>
  )
}
