import {Divider} from 'primereact/divider'
import {InputText} from 'primereact/inputtext'
import styles from './styles.module.scss'
import {Button} from 'primereact/button'
import {useCreateUser} from '../../../../../queries/createUser'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object({
  firstName: yup.string().required('First Name is a required field'),
  lastName: yup.string().required('Last Name is a required field'),
  email: yup.string().email('Must be valid email').required('Email is a required field'),
  username: yup.string().required('Username is a required field'),
  phoneNumber: yup.string().required('Phone is a required field'),
  password: yup
    .string()
    .required('Password is a required field')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
})

export const CreateUserView = () => {
  const createUser = useCreateUser()
  const form = useForm({
    resolver: yupResolver(schema),
  })
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = form
  const navigate = useNavigate()
  const onSubmit = (data: any) => {
    createUser.mutate(data, {
      onSuccess: () => {
        navigate('../')
      },
    })
  }
  return (
    <div style={{padding: '1rem'}}>
      <div style={{margin: '2rem'}}>
        <h2>Create New User</h2>
        <Divider />
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formInput}>
            <label htmlFor='firstName'>First Name</label>
            <InputText
              id='firstName'
              type='text'
              aria-describedby='firstName-help'
              {...register('firstName')}
              invalid={!!errors.firstName}
            />
            {errors.firstName && (
              <label id='firstName-error' style={{color: 'red'}}>
                {errors.firstName.message}
              </label>
            )}
          </div>
          <div className={styles.formInput}>
            <label htmlFor='lastName'>Last Name</label>
            <InputText
              id='lastName'
              type='text'
              aria-describedby='lastName-help'
              {...register('lastName')}
              invalid={!!errors.lastName}
            />
            {errors.lastName && (
              <label id='lastName-error' style={{color: 'red'}}>
                {errors.lastName.message}
              </label>
            )}
          </div>
          <div className={styles.formInput}>
            <label htmlFor='email'>Email</label>
            <InputText
              id='email'
              type='text'
              aria-describedby='email-help'
              {...register('email')}
              invalid={!!errors.email}
            />
            {errors.email && (
              <label id='email-error' style={{color: 'red'}}>
                {errors.email.message}
              </label>
            )}
          </div>
          <div className={styles.formInput}>
            <label htmlFor='username'>Username</label>
            <InputText
              id='username'
              type='text'
              aria-describedby='username-help'
              {...register('username')}
              invalid={!!errors.username}
            />
            {errors.username && (
              <label id='username-error' style={{color: 'red'}}>
                {errors.username.message}
              </label>
            )}
          </div>
          <div className={styles.formInput}>
            <label htmlFor='phone'>Phone</label>
            <InputText
              id='phone'
              type='text'
              aria-describedby='phone-help'
              {...register('phoneNumber')}
              invalid={!!errors.phoneNumber}
            />
            {errors.phoneNumber && (
              <label id='phone-error' style={{color: 'red'}}>
                {errors.phoneNumber.message}
              </label>
            )}
          </div>
          <div className={styles.formInput}>
            <label htmlFor='password'>Password</label>
            <InputText
              id='password'
              aria-describedby='password-help'
              type='password'
              {...register('password')}
              invalid={!!errors.password}
            />
            {errors.password && (
              <label id='password-error' style={{color: 'red'}}>
                {errors.password.message}
              </label>
            )}
          </div>
          <Button label='Submit' rounded type='submit' />
        </form>
      </div>
    </div>
  )
}
