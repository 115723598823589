import { DOTS } from '../../constants/general';
import usePagination from '../../hooks/usePagination';
import PaginationItemArrow from './components/PaginationItemArrow';

import type { IPagination } from './interfaces/IPagination';

import styles from './sass/Pagination.module.scss';

export default function Pagination({
  onPageClick, currentPage = 1, totalPageCount = 0,
}: IPagination) {
  const paginationRange = usePagination({
    currentPage,
    totalPageCount,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }
  const lastPage = paginationRange[paginationRange.length - 1];
  const getNextPage = () => {
    if (currentPage < +lastPage) {
      return currentPage + 1;
    }
    return currentPage;
  };

  const getPrevPage = () => {
    if (currentPage !== 1) {
      return currentPage - 1;
    }
    return currentPage;
  };

  return (
    <div className={styles.pagination}>
      <PaginationItemArrow
        onClick={() => onPageClick(getPrevPage())}
        disabled={currentPage === 1}
      />
      <div className={styles.numberContainer}>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <div key={['dots', index].join('_')} className={styles.dots}>...</div>;
          }

          return (
            <div
              key={pageNumber}
              onClick={() => onPageClick(pageNumber as number)}
              onKeyDown={() => onPageClick(pageNumber as number)}
              className={`${currentPage === pageNumber ? styles.active : ''}`}
              role="presentation"
            >
              {pageNumber}
            </div>
          );
        })}
      </div>
      <PaginationItemArrow
        btnNext
        onClick={() => onPageClick(getNextPage())}
        disabled={currentPage === +lastPage}
      />
    </div>
  );
}
