import removeHtmlTags from './removeHtmlTags'

export function formatAndSanitizeContent(content: string) {
  try {
    const contentObject = JSON.parse(content) as {content: string; context: string}
    return removeHtmlTags(contentObject.content)
  } catch {
    return ''
  }
}
