import {useQuery} from '@tanstack/react-query'
import {PythiaService} from '../../../../../api/services/PythiaService'
import {Checkbox} from 'primereact/checkbox'

export const usePrompts = () =>
  useQuery({
    queryFn: () => PythiaService.pythiaGetOracleOfferings(),
    queryKey: ['pythiaOfferings'],
  })

export const useCategorizedPrompts = () =>
  useQuery({
    queryFn: () => PythiaService.pythiaGetOracleOfferings(),
    queryKey: ['pythiaCategorizedOfferings'],
    select: (data) => {
      const categories = data.map((offering) => offering.category)
      const uniqueCategories = [...new Set(categories)]
      const result = uniqueCategories.map((category) => ({
        id: category,
        key: category,
        label: category,
        name: category,
        data: {
          name: category,
          description: '',
        },
        children: data
          .filter((offering) => offering.category === category)
          .map((offering) => ({
            id: offering.id,
            key: offering.id,
            label: offering.name,
            name: offering.name,
            data: {
              name: offering.name,
              description: offering.notes,
            },
            description: offering.notes,
          })),
      }))
      console.log('result', result)
      return result
    },
  })
