/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalysisFeedback } from '../models/AnalysisFeedback';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnalysisOutcomeService {

    /**
     * @param id 
     * @param feedback 
     * @param comment 
     * @returns any 
     * @throws ApiError
     */
    public static analysisOutcomeAddFeedback(
id: string,
feedback?: AnalysisFeedback,
comment?: string | null,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/AnalysisOutcome/{id}/feedback',
            path: {
                'id': id,
            },
            query: {
                'feedback': feedback,
                'comment': comment,
            },
        });
    }

    /**
     * @param id 
     * @returns any 
     * @throws ApiError
     */
    public static analysisOutcomeGetFeedback(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/AnalysisOutcome/{id}/feedback',
            path: {
                'id': id,
            },
        });
    }

}
