import {useQuery} from '@tanstack/react-query'
import {PythiaService} from 'src/api'

type Options = {
  subCategoryId?: string
}

const getSuggestions = async ({subCategoryId}: Options) => {
  return await PythiaService.pythiaGetSuggestions(
    subCategoryId,
    undefined,
    undefined,
    undefined,
    50
  )
}

export const useSuggestions = ({subCategoryId}: Options) =>
  useQuery({
    queryKey: ['askMeSuggestions', {subCategoryId}],
    queryFn: () => getSuggestions({subCategoryId}),
  })
