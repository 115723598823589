import {FilterMatchMode} from 'primereact/api'
import {Column} from 'primereact/column'
import {
  DataTableFilterMeta,
  DataTableSelectionMultipleChangeEvent,
  DataTable,
} from 'primereact/datatable'
import {IconField} from 'primereact/iconfield'
import {InputIcon} from 'primereact/inputicon'
import {InputText} from 'primereact/inputtext'
import {useMemo, useState} from 'react'
import {PythiaOffering} from '../../../../../../api'
import {
  useAssignPromptToCategory,
  useUnAssignPromptToCategory,
} from '../../../Pythia/queries/assignPromptToCategory'
import {useParams} from 'react-router-dom'
import {useCategory} from '../../../Pythia/queries/getCategory'
import {usePrompts} from '../../../Pythia/queries/getPrompts'
import {TriStateCheckbox} from 'primereact/tristatecheckbox'
import {MultiSelect} from 'primereact/multiselect'
import Select from 'react-select'
import {minWidth} from 'devexpress-dashboard/model/items/card/metadata/_card-layout'

export const AssignPromptsView = () => {
  const prompts = usePrompts()
  const {categoryId} = useParams()
  const category = useCategory(categoryId ?? '')
  const [activeSelectedFilter, setActiveSelectedFilter] = useState<
    {value: string; label?: string} | undefined
  >(undefined)
  const filteredPrompts = useMemo(
    () =>
      prompts.data?.filter((prompt) => !!category.data?.promptIds?.includes(prompt.id ?? '')) ?? [],
    [prompts.data, category.data]
  )
  const assignPrompts = useAssignPromptToCategory()
  const unAssignPrompts = useUnAssignPromptToCategory()
  const [selectedPrompts, setSelectedPrompts] = useState<PythiaOffering[]>(filteredPrompts)
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  })
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const displayedPrompts = useMemo(() => {
    if (activeSelectedFilter?.value === 'selected') {
      return selectedPrompts
    } else if (activeSelectedFilter?.value === 'not-selected') {
      return prompts.data?.filter(
        (prompt) => !selectedPrompts.some((selected) => selected.id === prompt.id)
      )
    }
    return prompts.data
  }, [activeSelectedFilter, selectedPrompts])

  const handleSelect = (e: DataTableSelectionMultipleChangeEvent<PythiaOffering[]>) => {
    setSelectedPrompts(e.value ?? [])

    const promptsToUnassign = filteredPrompts.filter((prompt) => {
      return !e.value?.some((ev) => ev.id === prompt.id)
    })
    const promptsToAssign = e.value?.filter((prompt) => {
      return !filteredPrompts.some((fp) => fp.id === prompt.id)
    })
    console.log('promptsToUnassign', promptsToUnassign)
    promptsToAssign.forEach((prompt) => {
      assignPrompts.mutate({
        categoryId: category.data?.id ?? '',
        promptId: prompt.id ?? '',
      })
    })

    promptsToUnassign.forEach((prompt) => {
      unAssignPrompts.mutate({
        categoryId: category.data?.id ?? '',
        promptId: prompt.id ?? '',
      })
    })
  }

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let _filters = {...filters}

    // @ts-ignore
    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      minWidth: 200,
    }),
  }

  const ActiveSelectedFilterSelect = () => {
    return (
      <Select
        styles={customStyles}
        value={activeSelectedFilter}
        options={[
          {label: 'All', value: 'all'},
          {label: 'Selected', value: 'selected'},
          {label: 'Not Selected', value: 'not-selected'},
        ]}
        // itemTemplate={representativesItemTemplate}
        onChange={(e) => setActiveSelectedFilter({label: e?.label, value: e?.value ?? ''})}
        placeholder='Selected Items Filter'
        className='p-column-filter'
      />
    )
  }

  const renderHeader = () => {
    return (
      <div
        className='flex'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 20,
        }}
      >
        <ActiveSelectedFilterSelect />
        <IconField iconPosition='left'>
          <InputIcon className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </IconField>
      </div>
    )
  }

  return (
    <div>
      <h3>Assign Actions</h3>
      <DataTable
        value={displayedPrompts}
        selection={selectedPrompts}
        selectionMode={null}
        onSelectionChange={handleSelect}
        dataKey='id'
        filters={filters}
        globalFilterFields={['name', 'category', 'notes']}
        header={renderHeader}
        filterDisplay='row'
      >
        <Column selectionMode='multiple' headerStyle={{width: '3rem'}} />
        <Column sortable field='name' header='Name' />
        <Column sortable field='category' header='Category' />
        <Column sortable field='notes' header='Description' />
      </DataTable>
    </div>
  )
}
