import {useParams} from 'react-router-dom'
import {usePrompts} from '../../../Pythia/queries/getPrompts'
import {useCategory} from '../../../Pythia/queries/getCategory'
import {DataView} from 'primereact/dataview'
import {Divider} from 'primereact/divider'
import {PythiaOffering} from '../../../../../../api'
import {Chip} from 'primereact/chip'
import {useMemo} from 'react'

function sortAlphabetically(a: PythiaOffering, b: PythiaOffering) {
  return (a.name?.toLowerCase().trim() ?? '') > (b.name?.toLowerCase().trim() ?? '') ? 1 : -1
}

export const PromptsView = () => {
  const prompts = usePrompts()
  const {categoryId} = useParams()
  const category = useCategory(categoryId ?? '')
  const filteredPrompts = useMemo(
    () =>
      prompts.data
        ?.sort(sortAlphabetically)
        ?.filter((prompt) => category.data?.promptIds?.includes(prompt.id ?? '')),
    [prompts.data, category.data]
  )

  return (
    <div>
      <div
        style={{
          marginBottom: '1rem',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <h3>Assigned Actions</h3>
      </div>
      <DataView value={filteredPrompts} itemTemplate={promptTemplate} />
    </div>
  )
}

const promptTemplate = (prompt: PythiaOffering) => {
  return (
    <div>
      <h4>{prompt.name}</h4>
      <div style={{marginBottom: '1rem'}}>{prompt.notes}</div>
      <Chip label={prompt.category} />
      <Divider />
    </div>
  )
}
