import React from 'react'
import {Provider} from 'react-redux'
import * as Sentry from '@sentry/browser'
import {PersistGate} from 'redux-persist/integration/react'

import {persistor, store} from './store/store'
import App from './App'

// eslint-disable-next-line import/no-extraneous-dependencies
// import 'bootstrap/dist/css/bootstrap.min.css'

// function receiveMessage(event: {data: {type?: any; token?: any; parentUrl?: any}}) {
//   if (event.data.type === 'INIT_DATA') {
//     const {token, parentUrl} = event.data
//     // Example: Setting up Axios for API calls
//     axios.defaults.baseURL = parentUrl
//     localStorage.setItem('kt-auth-react-v', token)
//     localStorage.setItem('parentUrl', parentUrl)
//   }
// }

// Add the event listener as soon as possible
// window.addEventListener('message', receiveMessage)

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 1.0,
    })
}

export const PythiaV3 = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App/>
        </PersistGate>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
