import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type IComplaints from './interfaces/IContentItems';
import IContentItems from "./interfaces/IContentItems";

export const initialState: IContentItems = {
  isModalOpen: false,
  activeAnalysisCategory: undefined,
  activeOracleOffering: undefined,
};

const contentItemsSlice = createSlice({
  name: 'contentItems',
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.isModalOpen = !state.isModalOpen;
    },
    setContentItemsState: (
      state,
      action: PayloadAction<Partial<IComplaints>>,
    ) => ({...state, ...action.payload}),
  },
});

export const {toggleModal, setContentItemsState} = contentItemsSlice.actions;

export default contentItemsSlice.reducer;
