import { useCallback, useRef } from 'react';

import { THROTTLE_DEFAULT_LIMIT } from '../constants/general';

const useThrottle = (callback: (...args: any[]) => void, limit = THROTTLE_DEFAULT_LIMIT) => {
  const waiting = useRef(false);

  return useCallback(
    (...args: any[]) => {
      if (!waiting.current) {
        callback(...args);
        waiting.current = true;
        setTimeout(() => {
          waiting.current = false;
        }, limit);
      }
    },
    [callback, limit],
  );
};

export default useThrottle;
