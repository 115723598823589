import {IAssistFilesResponse} from '../interfaces/IAssistFilesResponse'
import {IAssistFilesParams} from '../interfaces/IAssistFilesParams'
import {ICreateAssistFileResponse} from '../interfaces/ICreateAssistFileResponse'
import {ICreateAssistFileParams} from '../interfaces/ICreateAssistFileParams'
import {IDeleteAssistFileResponse} from '../interfaces/IDeleteAssistFileResponse'
import {IDeleteAssistFileParams} from '../interfaces/IDeleteAssistFileParams'
import {IUpdateAssistFileParams} from '../interfaces/IUpdateAssistFileParams'
import {IUpdateAssistFileResponse} from '../interfaces/IUpdateAssistFileResponse'
import assistApi from '../../../api/assistApi'
import {ASSIST_FILES} from '../../../../../../common/constants/requestUrls'
import {CONTENT_ITEMS_DEFAULT_PAGE_SIZE} from '../../../../../../common/constants/general'
import {DELETE, GET, POST, PUT} from '../../../../../../common/constants/requestMethods'

const assistFilesApi = assistApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAssistFiles: builder.query<IAssistFilesResponse, IAssistFilesParams>({
      query: ({searchInput}) => ({
        url: `${ASSIST_FILES}`,
        params: {
          PageNumber: 1,
          PageSize: CONTENT_ITEMS_DEFAULT_PAGE_SIZE,
          SearchInput: searchInput,
        },
        method: GET,
      }),
      providesTags: ['AssistFiles'],
    }),
    createFile: builder.mutation<ICreateAssistFileResponse, ICreateAssistFileParams>({
      query: ({typeId, statusId, subject, content}) => ({
        url: `${ASSIST_FILES}`,
        method: POST,
        body: {
          typeId,
          statusId,
          subject,
          content,
        },
      }),
      invalidatesTags: ['AssistFiles'],
    }),
    updateFile: builder.mutation<IUpdateAssistFileResponse, IUpdateAssistFileParams>({
      query: ({id, subject, content}) => ({
        url: `${ASSIST_FILES}/${id}`,
        method: PUT,
        body: {
          subject,
          content,
        },
      }),
      invalidatesTags: ['AssistFiles'],
    }),
    deleteFile: builder.mutation<IDeleteAssistFileResponse, IDeleteAssistFileParams>({
      query: ({id}) => ({
        url: `${ASSIST_FILES}/${id}`,
        method: DELETE,
      }),
      invalidatesTags: ['AssistFiles'],
    }),
  }),
})

export const {
  useFetchAssistFilesQuery,
  useCreateFileMutation,
  useUpdateFileMutation,
  useDeleteFileMutation,
} = assistFilesApi

export default assistFilesApi
