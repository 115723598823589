import {useParams} from 'react-router-dom'
import {useRoles} from '../../../../../../queries/getRoles'
import {Chip} from 'primereact/chip'
import {
  DataTable,
  DataTableFilterMeta,
  DataTableSelectionMultipleChangeEvent,
} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {RoleDto} from '../../../../../../api'
import {useState} from 'react'
import {FilterMatchMode} from 'primereact/api'
import {IconField} from 'primereact/iconfield'
import {InputIcon} from 'primereact/inputicon'
import {InputText} from 'primereact/inputtext'
import {
  useAssignRoleToType,
  useUnAssignRoleToType,
} from '../../../../PythiaV3/pages/Complaints/queries/assignRoleToType'
import {useTypeRoles} from '../../../../../common/queries/getTypeRoles'

export const RolesView = () => {
  const roles = useRoles({page: 1, pageSize: 40, search: ''})
  const {typeId} = useParams()
  const typeRoles = useTypeRoles(typeId ?? '')
  const assignRoles = useAssignRoleToType()
  const unAssignRoles = useUnAssignRoleToType()
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  })
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const handleSelect = (e: DataTableSelectionMultipleChangeEvent<RoleDto[]>) => {
    const rolesToUnassign = typeRoles.data?.filter((role) => !e.value?.includes(role))

    const rolesToAssign = e.value?.filter((role) => !typeRoles.data?.includes(role))

    if (rolesToUnassign) {
      rolesToUnassign.forEach((role) => {
        unAssignRoles.mutate({contentItemTypeId: typeId ?? '', roleId: role.id ?? ''})
      })
    }

    if (rolesToAssign) {
      rolesToAssign.forEach((role) => {
        assignRoles.mutate({contentItemTypeId: typeId ?? '', roleId: role.id})
      })
    }
  }
  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let _filters = {...filters}

    // @ts-ignore
    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <IconField iconPosition='left'>
          <InputIcon className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Roles Search'
          />
        </IconField>
      </div>
    )
  }

  const header = renderHeader()
  return (
    <div>
      <h3>Assigned Roles</h3>
      <div style={{display: 'flex', gap: '0.5rem', marginTop: '2rem'}}>
        {typeRoles.data?.map((role) => (
          <div key={role.id}>
            <Chip label={role.name ?? ''} />
          </div>
        ))}
      </div>

      <h3 style={{marginTop: '3rem'}}>Assign Roles</h3>
      <DataTable
        value={roles.data?.items ?? []}
        globalFilterFields={['name']}
        selection={typeRoles.data ?? []}
        dataKey='id'
        onSelectionChange={handleSelect}
        selectionMode={null}
        filters={filters}
        header={header}
      >
        <Column selectionMode='multiple' headerStyle={{width: '3rem'}} />

        <Column sortable field='name' header='Name' />
      </DataTable>
    </div>
  )
}
