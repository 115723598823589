/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {useLayout} from '../core'

const Footer: FC = () => {
  // const {classes} = useLayout()
  return (
    <div
      className={'footer py-4 d-flex flex-lg-column'}
      id='kt_footer'
      style={{paddingLeft: 92, textAlign: 'center'}}
    >
      {/*begin::Container*/}
      {/* <div className={clsx(classes.footerContainer, 'd-flex flex-column flex-md-row flex-stack')}> */}
      {/*begin::Copyright*/}
      <div className='text-dark order-2 order-md-1'>
        <p>
          Artificial Intelligence (AI) can occasionally make errors. Please verify all critical
          information independently.
        </p>
        <p>
          Refer to the accepted{' '}
          <a href='https://www.agedcareai.com.au/disclaimer' target='_blank'>
            Disclaimer
          </a>{' '}
          &{' '}
          <a href='https://www.agedcareai.com.au/user-license' target='_blank'>
            T&Cs
          </a>{' '}
          for further details. Powered by{' '}
          <a href='https://www.agedcareai.com.au/' target='_blank'>
            AgedCareAI.
          </a>
        </p>
      </div>
      {/*end::Copyright*/}

      {/*begin::Menu*/}
      {/* <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <a href='https://keenthemes.com/metronic' target='_blank' className='menu-link px-2'>
              About
            </a>
          </li>

          <li className='menu-item'>
            <a href='https://keenthemes.com/metronic' target='_blank' className='menu-link px-2'>
              Support
            </a>
          </li>

          <li className='menu-item'>
            <a href='https://keenthemes.com/metronic' target='_blank' className='menu-link px-2'>
              Purchase
            </a>
          </li>
        </ul> */}
      {/*end::Menu*/}
      {/* </div> */}
      {/*end::Container*/}
    </div>
  )
}

export {Footer}
