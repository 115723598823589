import {useQuery} from '@tanstack/react-query'
import {ContentItemService} from '../../../../../api'

export const useStatuses = (id?: string) => {
  return useQuery({
    queryKey: ['statuses', id],
    queryFn: () => {
      return ContentItemService.contentItemGetContentItemStatuses(id!)
    },
    enabled: !!id,
  })
}

const getAvailablePrompts = async (id: string) => {
  const status = await ContentItemService.contentItemGetContentItemStatusById(id)
  const type = await ContentItemService.contentItemGetContentItemTypeById(
    status.contentItemTypeId ?? ''
  )
  const categories = await Promise.all(
    type.analysisCategories?.map(async (category) => {
      return await ContentItemService.contentItemGetAnalysisCategoryById(category.id!)
    }) ?? []
  )
  const result = categories.flatMap((category) => category.promptIds ?? [])
  return result
}

export const useAvailableStatusPrompts = (id?: string) => {
  return useQuery({
    queryKey: ['availablePrompts', id],
    queryFn: () => getAvailablePrompts(id!),
    enabled: !!id,
  })
}
