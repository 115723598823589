import React, {PropsWithChildren} from 'react'
import {useState} from 'react'
import {AssistService} from '../../../../../api/services/AssistService'

interface ContextProps {
  promptContext: {content: string; context: string}
  requestPrompt: (prompt: string) => void
  sidebarVisible: boolean
  setPromptContext: (context: {content: string; context: string}) => void
  setSidebarVisible: (visible: boolean) => void
}

interface RequestResult {
  result: string
}

const defaultPromptContext = {content: '', context: ''}

const AiSidebarContext = React.createContext<ContextProps>({
  promptContext: defaultPromptContext,
  requestPrompt: (prompt: string): Promise<RequestResult> => new Promise(() => {}),
  sidebarVisible: false,
  setPromptContext: (context) => {},
  setSidebarVisible: () => {},
})

const AiSidebarProvider = ({children}: PropsWithChildren<{}>) => {
  const [sidebarVisible, setSidebarVisible] = useState(true)
  const [promptContext, setPromptContext] = useState(defaultPromptContext)

  const requestPrompt = async (prompt: string) => {
    const fullPromptObject = {
      prompt,
      content: defaultPromptContext.content,
      context: defaultPromptContext.context,
    }
    const res = await AssistService.assistGerenatePrompt(JSON.stringify(fullPromptObject))
    return res.result
  }

  return (
    <AiSidebarContext.Provider
      value={{
        requestPrompt,
        promptContext,
        sidebarVisible,
        setPromptContext,
        setSidebarVisible: (visible: boolean) => {
          setSidebarVisible(visible)
        },
      }}
    >
      {children}
    </AiSidebarContext.Provider>
  )
}

export {AiSidebarContext}
export default AiSidebarProvider
