import {useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {useSetAnalysisFeedbackMutation} from '../api/useSetAnalysisFeedbackMutation'

type FeedbackModalProps = {
  id: string
  isOpen: boolean
  onClose: () => void
}

export const FeedbackModal = ({id, isOpen, onClose}: FeedbackModalProps) => {
  const [feedbackComment, setFeedbackComment] = useState<string>('')
  const setFeedback = useSetAnalysisFeedbackMutation()
  const [showError, setShowError] = useState(false)
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackComment(e.target.value)
  }
  const handleSubmit = () => {
    if (!feedbackComment) {
      setShowError(true)
      return
    }
    setFeedback.mutate(
      {
        id,
        feedback: -1,
        comment: feedbackComment,
      },
      {onSuccess: () => handleClose()}
    )
  }

  const handleClose = () => {
    if (setFeedback.isPending) return
    onClose()
  }

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Feedback</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              onChange={handleChange}
              required
              isInvalid={showError}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose} disabled={setFeedback.isPending}>
          Close
        </Button>
        <Button variant='primary' onClick={handleSubmit} disabled={setFeedback.isPending}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
