export interface IAssistFileContent {
  content: string
  context: string
}

export const useFileContent = (contentText: string): IAssistFileContent => {
  try {
    return JSON.parse(contentText) as IAssistFileContent
  } catch (e) {
    return {content: '', context: ''}
  }
}
