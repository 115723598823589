import {useDispatch} from 'react-redux'

import {useAppSelector} from '../../../../store/hooks/useApp'


import type {ReactElement} from 'react'
import type {IAnalysisCategoryProps} from './interfaces/IAnalysisCategory'

import styles from './sass/IncidentBlockModal.module.scss'
import contentItemsSelector from "../../../../../../common/store/slices/contentItems/selectors";
import {setContentItemsState} from "../../../../../../common/store/slices/contentItems/slice";

export default function AnalysisCategory({
                                           item,
                                           oracleOffering,
                                           hasSearch,
                                         }: IAnalysisCategoryProps): ReactElement {
  const dispatch = useDispatch()
  const {activeAnalysisCategory} = useAppSelector(contentItemsSelector)

  return (
    <div
      className={`${styles.analysisCategoriesItem} ${
        item.id === activeAnalysisCategory?.id && !hasSearch ? styles.active : ''
      }`}
      role='presentation'
      onClick={() => {
        dispatch(
          setContentItemsState({
            activeAnalysisCategory: item,
            activeOracleOffering: oracleOffering?.filter(({id}) => item?.promptIds?.includes(id)),
          })
        )
      }}
    >
      <p className={styles.analysisCategoriesItemTitle}>{item.name}</p>
    </div>
  )
}
