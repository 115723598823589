import {Divider} from 'primereact/divider'
import {InputText} from 'primereact/inputtext'
import styles from './styles.module.scss'
import {Button} from 'primereact/button'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useUpdateUser} from '../../../../../queries/editUser'
import {useUser} from '../../../../../queries/getUser'

const schema = yup.object({
  firstName: yup.string().required('First Name is a required field'),
  lastName: yup.string().required('Last Name is a required field'),
  email: yup.string().email('Must be valid email').required('Email is a required field'),
  username: yup.string().required('Username is a required field'),
  phone: yup.string().required('Phone is a required field'),
})

export const EditUserView = () => {
  const {userId} = useParams()
  const {data: user} = useUser(userId ?? '')
  const updateUser = useUpdateUser()
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
      username: user?.userName ?? '',
      phone: user?.phone ?? '',
    },
  })

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = form

  const navigate = useNavigate()

  const onSubmit = (data: any) => {
    updateUser.mutate(
      {...data, id: userId},
      {
        onSuccess: () => {
          navigate('../')
        },
      }
    )
  }
  const handleBackClick = () => {
    navigate(-1)
  }
  return (
    <div style={{padding: '1rem'}}>
      <Button icon='pi pi-arrow-left' text size='large' onClick={handleBackClick} />

      <div style={{margin: '2rem'}}>
        <h2>Edit User</h2>
        <Divider />
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formInput}>
            <label htmlFor='firstName'>First Name</label>
            <InputText
              id='firstName'
              type='text'
              aria-describedby='firstName-help'
              {...register('firstName')}
              invalid={!!errors.firstName}
            />
            {errors.firstName && (
              <label id='firstName-error' style={{color: 'red'}}>
                {errors.firstName.message}
              </label>
            )}
          </div>
          <div className={styles.formInput}>
            <label htmlFor='lastName'>Last Name</label>
            <InputText
              id='lastName'
              type='text'
              aria-describedby='lastName-help'
              {...register('lastName')}
              invalid={!!errors.lastName}
            />
            {errors.lastName && (
              <label id='lastName-error' style={{color: 'red'}}>
                {errors.lastName.message}
              </label>
            )}
          </div>
          <div className={styles.formInput}>
            <label htmlFor='email'>Email</label>
            <InputText
              id='email'
              type='text'
              aria-describedby='email-help'
              {...register('email')}
              invalid={!!errors.email}
            />
            {errors.email && (
              <label id='email-error' style={{color: 'red'}}>
                {errors.email.message}
              </label>
            )}
          </div>
          <div className={styles.formInput}>
            <label htmlFor='username'>Username</label>
            <InputText
              id='username'
              type='text'
              aria-describedby='username-help'
              {...register('username')}
              invalid={!!errors.username}
            />
            {errors.username && (
              <label id='username-error' style={{color: 'red'}}>
                {errors.username.message}
              </label>
            )}
          </div>
          <div className={styles.formInput}>
            <label htmlFor='phone'>Phone</label>
            <InputText
              id='phone'
              type='text'
              aria-describedby='phone-help'
              {...register('phone')}
              invalid={!!errors.phone}
            />
            {errors.phone && (
              <label id='phone-error' style={{color: 'red'}}>
                {errors.phone.message}
              </label>
            )}
          </div>
          <Button label='Submit' rounded type='submit' loading={updateUser.isPending} />
        </form>
      </div>
    </div>
  )
}
