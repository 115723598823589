const clientDropdownItems = [
  {
    id: 1,
    title: 'Residents',
  },
  {
    id: 2,
    title: 'Relatives',
  },
  {
    id: 3,
    title: 'Registered Nurses',
  },
  {
    id: 4,
    title: 'Clinical Care',
  },
  {
    id: 5,
    title: 'Other',
  },
];

export default clientDropdownItems;
