import {useMutation} from '@tanstack/react-query'
import {queryClient} from '../libs/react-query'
import {IdentityService} from '../api'

export const useCreateUser = () => {
  return useMutation({
    mutationFn: IdentityService.identityCreateUser,
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['users']}),
  })
}
