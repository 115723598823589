import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {InputText} from 'primereact/inputtext'
import {useCallback, useState} from 'react'
import {useCreateContentType} from '../../../Pythia/queries/createType'
import {useForm} from 'react-hook-form'
import {useDashboard} from '../../../../../common/queries/getDashboard'

type TypeData = {
  name: string
  description: string
  dashboardId: string
}

interface Props {
  dashboardId: string
}

export const CreateTypeButton = ({dashboardId}: Props) => {
  const {data: dashboard} = useDashboard(dashboardId)
  const [visible, setVisible] = useState(false)
  const onHide = () => setVisible(false)
  const onClick = () => setVisible(true)
  const createType = useCreateContentType()
  const form = useForm<TypeData>()
  const {register, handleSubmit} = form
  const onSubmit = useCallback(
    (data: TypeData) => {
      data.dashboardId = dashboard?.id ?? ''
      createType.mutate(data, {onSuccess: onHide})
    },
    [dashboard?.id]
  )

  const footerContent = (
    <div>
      <Button
        label='Cancel'
        icon='pi pi-times'
        onClick={() => setVisible(false)}
        className='p-button-text'
      />
      <Button
        label='Submit'
        icon='pi pi-check'
        autoFocus
        type='submit'
        loading={createType.isPending}
        form={'typeForm'}
      />
    </div>
  )
  return (
    <>
      <Button icon='pi pi-plus' label='Add New Type' onClick={onClick} />
      <Dialog
        header='Create Type'
        visible={visible}
        style={{width: '30rem'}}
        onHide={onHide}
        footer={footerContent}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={'typeForm'}>
          <div className='p-fluid'>
            <div className='p-field'>
              <label htmlFor='name'>Name</label>
              <InputText id='name' type='text' {...register('name', {required: true})} />
            </div>
            <div className='p-field'>
              <label htmlFor='description'>Description</label>
              <InputText id='description' type='text' {...register('description')} />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  )
}
