import {useMutation} from '@tanstack/react-query'
import {ContentItemService} from '../../../../../../api'
import {queryClient} from '../../../../../../libs/react-query'

export const useDeleteContentItem = () =>
  useMutation({
    mutationFn: ContentItemService.contentItemDeleteContentItem,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['contentItems']})
    },
  })
