import {useNavigate, useParams} from 'react-router-dom'
import {useSuggestions} from '../api/getSuggestions'
import {useCategories} from '../api/getCategories'
import {Avatar} from 'primereact/avatar'
import {Divider} from 'primereact/divider'
import {Dropdown, DropdownChangeEvent} from 'primereact/dropdown'
import {usePrompts} from '../../Pythia/queries/getPrompts'
import {useUpdateCategory} from '../api/updateCategory'
import {useMemo, useState} from 'react'
import {Button} from 'primereact/button'
import {InputText} from 'primereact/inputtext'
import {useDeleteCategory} from '../api/deleteCategory'
import {useDeleteSuggestion} from '../api/deleteSuggestion'
import {useAddSuggestion} from '../api/addSuggestion'
import {Dialog} from 'primereact/dialog'
import {useForm} from 'react-hook-form'

type FormData = {
  text: string
}

export const SubCategoriesView = () => {
  const {subCategoryId} = useParams()
  const suggestions = useSuggestions({subCategoryId})
  const form = useForm<FormData>()
  const [visible, setVisible] = useState(false)
  const handleHide = () => setVisible(false)
  const onClick = () => setVisible(true)
  const {register, handleSubmit} = form
  const updateCategory = useUpdateCategory()
  const prompts = usePrompts()
  const [isEditing, setIsEditing] = useState(false)
  const deleteCategory = useDeleteCategory()
  const navigate = useNavigate()
  const deleteSuggestion = useDeleteSuggestion()
  const createSuggestion = useAddSuggestion()
  const categories = useCategories()
  const subCategory = categories.data
    ?.find((category) => category.children.find((subCategory) => subCategory.id === subCategoryId))
    ?.children.find((subCategory) => subCategory.id === subCategoryId)
  const [categoryName, setCategoryName] = useState(subCategory?.name ?? '')

  const actionId = categories.data
    ?.find((category) => category.children.find((subCategory) => subCategory.id === subCategoryId))
    ?.children.find((subCategory) => subCategory.id === subCategoryId)?.data.actionId
  const selectedAction = useMemo(() => {
    return prompts.data?.find((prompt) => prompt.id === actionId)
  }, [prompts.data, actionId])
  const subCategoryLabel = `${subCategory?.data?.name
    ?.charAt(0)
    .toUpperCase()}${subCategory?.data?.name?.charAt(1).toUpperCase()}`
  const handleActionChange = (e: DropdownChangeEvent) => {
    updateCategory.mutate({
      id: subCategoryId,
      actionId: e.value.id,
      name: subCategory?.name ?? '',
      parentCategory: subCategory?.data.parentCategory ?? '',
    })
  }

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }

  const handleCategoryNameChange = (e: any) => {
    setCategoryName(e.target.value)
  }

  const handleUpdateClick = () => {
    updateCategory.mutate(
      {
        id: subCategoryId ?? '',
        name: categoryName,
        parentCategory: subCategory?.data.parentCategory,
      },
      {}
    )
    setIsEditing(!isEditing)
  }

  const handleDeleteClick = () => {
    deleteCategory.mutate(subCategoryId ?? '', {
      onSuccess: () => {
        navigate('../')
      },
    })
  }

  const handleDeleteSuggestion = (suggestionId: string) => {
    deleteSuggestion.mutate(suggestionId)
  }

  const handleAddSuggestion = (data: FormData) => {
    createSuggestion.mutate(
      {subCategory: subCategoryId ?? '', text: data.text},
      {onSuccess: handleHide}
    )
  }

  const footerContent = (
    <div>
      <Button
        label='Cancel'
        icon='pi pi-times'
        onClick={() => setVisible(false)}
        className='p-button-text'
      />
      <Button
        label='Submit'
        icon='pi pi-check'
        autoFocus
        type='submit'
        loading={createSuggestion.isPending}
        form={'typeForm'}
      />
    </div>
  )
  return (
    <div style={{padding: '2rem'}}>
      <div style={{position: 'sticky', backgroundColor: 'white', top: 0, zIndex: 100}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <div style={{margin: '4px 20px 0px 0px'}}>
              <Avatar label={subCategoryLabel} shape='circle' size='xlarge' />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
              }}
            >
              {isEditing ? (
                <div style={{display: 'flex'}}>
                  <InputText value={categoryName} onChange={handleCategoryNameChange} />
                  <Button
                    icon='pi pi-check'
                    text
                    severity='info'
                    size='large'
                    onClick={handleUpdateClick}
                    disabled={updateCategory.isPending}
                  />
                </div>
              ) : (
                <h3 style={{paddingLeft: '16.250px'}}>{subCategory?.name}</h3>
              )}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <Button
              icon='pi pi-trash'
              text
              severity='danger'
              size='large'
              onClick={handleDeleteClick}
              disabled={deleteCategory.isPending}
            />
            <Button
              icon='pi pi-pencil'
              text
              severity='info'
              size='large'
              onClick={handleEditClick}
              disabled={updateCategory.isPending}
            />
          </div>
        </div>
        <div style={{margin: 'auto', marginTop: '1rem', display: 'flex', alignItems: 'center'}}>
          <h5 style={{margin: '0 1rem 0 0 '}}>Action Prompt: </h5>
          <Dropdown
            style={{width: '20rem'}}
            value={selectedAction}
            options={prompts.data ?? []}
            optionLabel='name'
            loading={prompts.isLoading}
            filter
            onChange={handleActionChange}
          />
        </div>
        <div
          style={{
            marginTop: '2rem',
            width: '100%',
          }}
        >
          <Divider />
        </div>
        <div>
          <Button label='Add New Suggestion' onClick={onClick} />
        </div>

        <div style={{display: 'flex', flexDirection: 'column', marginTop: '2rem'}}>
          <Divider />

          {suggestions.data?.data?.map((suggestion) => (
            <>
              <div
                key={suggestion.id}
                style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
              >
                <h5 style={{margin: 0}}>{suggestion.text}</h5>
                <Button
                  icon='pi pi-trash'
                  text
                  severity='danger'
                  size='large'
                  onClick={() => handleDeleteSuggestion(suggestion.id ?? '')}
                />
              </div>
              <Divider />
            </>
          ))}
        </div>
        <Dialog
          header='Add New Suggestion'
          visible={visible}
          style={{width: '30rem'}}
          onHide={handleHide}
          footer={footerContent}
        >
          <form onSubmit={handleSubmit(handleAddSuggestion)} id={'typeForm'}>
            <div className='p-fluid'>
              <div className='p-field'>
                <label htmlFor='text'>Suggestion</label>
                <InputText id='text' type='text' {...register('text', {required: true})} />
              </div>
            </div>
          </form>
        </Dialog>
      </div>
      {/* <PromptsView /> */}
    </div>
  )
}
