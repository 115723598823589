import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {usePageData} from '../../../_metronic/layout/core'
interface LinkDetails {
  title: string
  link: string
}

const linkMap: {[key: string]: LinkDetails} = {
  '1': {
    title: 'Dashboard',
    link: 'https://app.powerbi.com/reportEmbed?reportId=043510f4-5898-4245-b12b-b690261fa1fc&autoAuth=true&ctid=5136fe4d-f15e-498a-92cd-52593393b5c2',
  },
  '2': {
    title: 'Facilities',
    link: 'https://app.powerbi.com/reportEmbed?reportId=6fc9a148-6ad5-471b-9123-f2eaa4ae1d41&autoAuth=true&ctid=5136fe4d-f15e-498a-92cd-52593393b5c2',
  },
  '3': {
    title: 'Clinical',
    link: 'https://app.powerbi.com/reportEmbed?reportId=62d9f023-0a75-47b8-806d-0385d03a3f4b&autoAuth=true&ctid=5136fe4d-f15e-498a-92cd-52593393b5c2',
  },
  '4': {
    title: 'Nurse Call',
    link: 'https://app.powerbi.com/reportEmbed?reportId=9a86a180-246b-4d82-910e-77358361c7ed&autoAuth=true&ctid=5136fe4d-f15e-498a-92cd-52593393b5c2',
  },
  '5': {
    title: 'Painchek',
    link: 'https://app.powerbi.com/reportEmbed?reportId=75ef95be-41fd-436b-80c6-d97cfa76f6d4&autoAuth=true&ctid=5136fe4d-f15e-498a-92cd-52593393b5c2',
  },
}

export const PowerBIReports = () => {
  const {id} = useParams<string>()
  const {setPageTitle, setPageBreadcrumbs} = usePageData()
  useEffect(() => {
    id && setPageTitle(linkMap[id].title)
    setPageBreadcrumbs([])
    return () => {
      setPageBreadcrumbs([])
    }
  }, [id])
  return (
    <div style={{height: '100%'}}>
      {id && (
        <iframe
          style={{height: '100%'}}
          title={linkMap[id].title}
          width='100%'
          src={linkMap[id].link}
          // frameborder='0'
          // allowFullScreen='true'
        ></iframe>
      )}
    </div>
  )
}
