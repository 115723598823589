import type { ReactElement } from 'react';
import type { ICategories } from './interfaces/ICategories';

import { ReactComponent as Arrow } from '../../assets/icons/whiteSidebar/arrow.svg';

import styles from './sass/Categories.module.scss';

export default function Categories({ category, subCategory, className }: ICategories): ReactElement {
  return (
    <p className={`${styles.categories} ${className || ''}`}>
      {category}
      {' '}
      <Arrow className={styles.arrow} />
      {' '}
      {subCategory}
    </p>
  );
}
