import {useEffect, useRef, useState} from 'react'

import DotsLoader from '../../../../components/DotsLoader/DotsLoader'
import {useAppDispatch, useAppSelector} from '../../../../store/hooks/useApp'
import {useFetchInfiniteSuggestionsQuery} from '../../../../store/slices/chat/apis/chat'
import chatSelector from '../../../../store/slices/chat/selectors'
import categoriesSelector from '../../../../store/slices/categories/selectors'
import {setChatState, toggleModal} from '../../../../store/slices/chat/slice'
import Button from '../Button/Button'

import {ReactComponent as Book} from '../../../../assets/icons/chat/book.svg'
import searchIcon from '../../../../assets/icons/search.svg'

import styles from './sass/Suggestions.module.scss'
import {useOutsideAlerter} from '../../../../../../components/layout/SecondarySidebar/hooks/useOutsideAlerter'

function Suggestions() {
  const {isModalOpen} = useAppSelector(chatSelector)
  const {subCategory} = useAppSelector(categoriesSelector)
  const dispatch = useAppDispatch()

  const loaderRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')

  const sidebarRef = useRef<HTMLDivElement>(null) // Create a ref for the sidebar
  // Use the custom hook here
  useOutsideAlerter(sidebarRef, () => isModalOpen && dispatch(toggleModal()))

  const {data, isFetching} = useFetchInfiniteSuggestionsQuery({
    search,
    subCategory: subCategory?.id || '',
    pageNumber: currentPage,
  })

  const onOpenModal = () => {
    setCurrentPage(1)
    setSearch('')
    dispatch(toggleModal())
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting && data && data.pageNumber < data?.totalPages) {
        setCurrentPage((prev) => prev + 1)
      }
    })

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    const currentRef = loaderRef.current
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [data])

  const handleSearch = (value: string) => {
    setSearch(value)
    setCurrentPage(1)
  }

  const handleSuggestionClick = (suggestion: string) => {
    dispatch(
      setChatState({
        message: suggestion,
        isModalOpen: false,
      })
    )
  }

  return (
    <div>
      <Button icon={<Book />} onClick={onOpenModal} buttonStyle={styles.bookButton} />
      <div ref={sidebarRef} className={`${styles.modal} ${isModalOpen && styles.visible}`}>
        <Button
          onClick={onOpenModal}
          icon={<i className='pi pi-times'></i>}
          buttonStyle={styles.closeButton}
        />
        <div className={styles.searchInput}>
          <div className={styles.searchIcon}>
            <img src={searchIcon} alt='search' />
          </div>
          <div className={styles.inputField}>
            <input
              type='text'
              value={search}
              onChange={(e) => handleSearch(e.currentTarget.value)}
              aria-label='Search'
              placeholder='Search'
            />
          </div>
        </div>
        {data?.data.length ? (
          <ul className={styles.suggestionList}>
            {data?.data.map(({id, text}, index) => (
              <li
                key={id}
                ref={index === data.data.length - 1 ? loaderRef : null}
                className={`${styles.suggestionItem} ${
                  index !== data.data.length - 1 ? styles.borderBottom : ''
                }`}
                onClick={() => handleSuggestionClick(text)}
                role='presentation'
              >
                <p className={styles.suggestionText}>{text}</p>
              </li>
            ))}
            <div>{!!isFetching && <DotsLoader variant='centered' />}</div>
          </ul>
        ) : (
          <div className={styles.noSuggestions}>
            <p>No suggestions found</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Suggestions
