import {ReactElement, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'

import {useOutsideAlerter} from 'src/app/components/layout/SecondarySidebar/hooks/useOutsideAlerter'

import book from 'src/app/common/assets/icons/whiteSidebar/admins.svg'
import clinical from 'src/app/common/assets/icons/whiteSidebar/clinical.svg'
import compliance from 'src/app/common/assets/icons/whiteSidebar/compliance.svg'
import feedback from 'src/app/common/assets/icons/whiteSidebar/feedback.svg'
import maintenance from 'src/app/common/assets/icons/whiteSidebar/maintenance.svg'
import marketing from 'src/app/common/assets/icons/whiteSidebar/marketing.svg'
import policies from 'src/app/common/assets/icons/whiteSidebar/policies.svg'
import preAccreditation from 'src/app/common/assets/icons/whiteSidebar/pre_acreditation.svg'
import training from 'src/app/common/assets/icons/whiteSidebar/training.svg'
import shortLogo from 'src/app/common/assets/images/ai-logo-64.png'

import ArrowButton from '../../ArrowButton/ArrowButton'
import NavItem from '../../NavItem/NavItem'
import styles from './sass/SecondarySidebar.module.scss'
import {useAppSelector} from '../../../pages/PythiaV3/store/hooks/useApp'
import dashboardSelector from '../../../common/store/slices/dashboard/selectors'
import {useDashboard} from '../../../common/queries/getDashboard'
import {useContentItemTypes} from '../../../common/queries/getTypes'

import navItemStyles from '../../NavItem/sass/NavItem.module.scss'

const iconMap = [
  book,
  clinical,
  marketing,
  policies,
  preAccreditation,
  compliance,
  maintenance,
  training,
  feedback,
]

const moduleNameMap: {[key: string]: string} = {
  agedcareai: 'Insights',
  assist: 'Assist',
}

export default function SecondarySidebar(): ReactElement {
  const {key} = useAppSelector(dashboardSelector) ?? {}
  const {data: dashboard} = useDashboard(key)
  const {data: types} = useContentItemTypes(dashboard?.id)
  const [isSidebarOpened, setIsSidebarOpened] = useState(true)
  const [openedItemId, setOpenedItemId] = useState('')
  const sidebarRef = useRef<HTMLDivElement>(null) // Create a ref for the sidebar

  const onClickHandler = (itemId: string) => {
    setOpenedItemId(itemId === openedItemId ? '0' : itemId)
  }

  const onArrowButtonClick = () => setIsSidebarOpened(!isSidebarOpened)
  const onSidebarClick = () => setIsSidebarOpened(true)

  // Use the custom hook here
  useOutsideAlerter(sidebarRef, () => setIsSidebarOpened(false))

  return (
    <div
      ref={sidebarRef} // Attach the ref here
      className={`${styles.whiteSidebar} ${
        isSidebarOpened ? styles.wideSidebar : styles.shortSidebar
      }`}
    >
      <div className={styles.navigationBrand}>
        <Link to='/' style={{overflow: 'hidden'}}>
          <div className={isSidebarOpened ? styles.logo : styles.shortLogo}>
            {isSidebarOpened ? (
              <h3 className={styles.textLogo}>{moduleNameMap[key]}</h3>
            ) : (
              <img src={shortLogo} alt='logo' height='48' />
            )}
          </div>
        </Link>
        <ArrowButton onArrowButtonClick={onArrowButtonClick} isSidebarOpened={isSidebarOpened} />
      </div>
      <div className={styles.menuContainer}>
        <nav role='presentation' onClick={onSidebarClick}>
          <ul className={styles.navContainer}>
            {key === 'assist' && (
              <li className={navItemStyles.item}>
                <div
                  className={`${navItemStyles.itemHeader} ${
                    openedItemId === 'my-files' && navItemStyles.activeItemHeader
                  }`}
                  role='presentation'
                >
                  <Link
                    to='my-files'
                    className={navItemStyles.submenuLink}
                    onClick={() => setOpenedItemId('my-files')}
                  >
                    My Files
                  </Link>
                </div>
              </li>
            )}
            {types?.length &&
              types.map(({id, name}, index) => (
                <NavItem
                  key={id}
                  icon={iconMap[index % 9]}
                  title={name ?? ''}
                  id={id ?? '0'}
                  onClickHandler={onClickHandler}
                  openedItemId={openedItemId}
                  isSidebarOpened={isSidebarOpened}
                />
              ))}
          </ul>
        </nav>
        <div className={styles.appName}>
          <h3 style={{color: '#57534f9c'}}>{moduleNameMap[key]}</h3>
        </div>
      </div>
    </div>
  )
}
