/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSuggestionDto } from '../models/CreateSuggestionDto';
import type { Suggestion } from '../models/Suggestion';
import type { UpdateSuggestionDto } from '../models/UpdateSuggestionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SuggestionsService {

    /**
     * @param requestBody 
     * @returns Suggestion 
     * @throws ApiError
     */
    public static suggestionsCreateSuggestion(
requestBody: CreateSuggestionDto,
): CancelablePromise<Suggestion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/Suggestions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns binary 
     * @throws ApiError
     */
    public static suggestionsDeleteSuggestion(
id: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/Suggestions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Suggestion 
     * @throws ApiError
     */
    public static suggestionsUpdateSuggestion(
id: string,
requestBody: UpdateSuggestionDto,
): CancelablePromise<Suggestion> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/admin/Suggestions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
