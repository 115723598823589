import {Route, Routes} from 'react-router-dom'
import {SubCategoriesView} from './subCategoryView'
import {CategoryView} from './categoryView'

export const SidebarContent = () => {
  return (
    <>
      <Routes>
        <Route path='category/:categoryId' element={<CategoryView />} />
        <Route path='subcategory/:subCategoryId' element={<SubCategoriesView />} />
      </Routes>
    </>
  )
}
