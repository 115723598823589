import { useQuery } from "@tanstack/react-query";
import { AccountService } from "../api";

export type UserQueryOptions = {
  page: number;
  pageSize: number;
  search?: string | null | undefined;
  sortBy?: string | null | undefined;
  sortDescending?: boolean | undefined;
};

export const useUsers = ({
  page,
  pageSize,
  search,
  sortBy,
  sortDescending,
}: UserQueryOptions) =>
  useQuery({
    queryFn: () =>
      AccountService.accountGetUsers(
        page,
        pageSize,
        search,
        sortBy,
        sortDescending
      ),
    queryKey: ["users", page, pageSize, search, sortBy, sortDescending],
  });
