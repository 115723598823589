import type {ReactElement} from 'react'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import Title from '../../components/Title/Title'

import {
  useFetchContentItemsQuery,
  useGetAnalysisCategoriesQuery,
} from 'src/app/common/store/slices/contentItems/apis/contentItems'
import IncidentBlock from './components/IncidentBlock/IncidentBlock'
import SearchInputBlock from './components/SearchInputBlock/SearchInputBlock'

import styles from './sass/ComplaintsView.module.scss'
import Button from '../../components/Button/Button'
import {ButtonVariant, ButtonWidthVariant} from '../../../../common/constants/enams'
import book from 'src/app/common/assets/icons/whiteSidebar/admins.svg'
import {useDispatch} from 'react-redux'

import DotsLoader from '../../../../components/DotsLoader/DotsLoader'
import useDebounce from '../../../../common/hooks/useDebounce'
import {useStatus} from '../../../../common/queries/getStatus'
import {CreateNewItemButton} from '../../../../components/CreateNewItemButton/CreateNewItemButton'
import {IContentItem} from '../../../../common/store/slices/contentItems/interfaces/IContentItemsResponse'
import {toggleModal} from '../../../../common/store/slices/contentItems/slice'

function ComplaintsView(): ReactElement {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm.trim())
  const {typeId, statusId} = useParams()
  const {data, isLoading} = useFetchContentItemsQuery({
    statusId: statusId ?? '',
    typeId: typeId ?? '',
    searchInput: debouncedSearchTerm,
  })
  const dispatch = useDispatch()

  const status = useStatus(statusId)
  const [activeIncident, setActiveIncident] = useState<IContentItem | undefined>(undefined)
  const {data: analysisCategories} = useGetAnalysisCategoriesQuery({
    statusId,
    typeId,
  })
  useEffect(() => {
    setActiveIncident(data?.items[0])
  }, [data])

  const handleToggleModalClick = () => {
    activeIncident && dispatch(toggleModal())
  }
  return isLoading ? (
    <DotsLoader variant='centered' />
  ) : (
    <div className={styles.container}>
      <div className={styles.pageHeader}>
        <Title text={status.data?.submenuTitle ?? ''} />
        <div className={styles.buttonContainer}>
          {analysisCategories && statusId && typeId ? (
            <Button
              title='AI'
              variant={ButtonVariant.PRIMARY}
              icon={book}
              widthVariant={ButtonWidthVariant.AI}
              onClick={handleToggleModalClick}
            />
          ) : null}
          <CreateNewItemButton />
          {/* <div className={styles.dropdowns}>
          <ClientsFilter />
          <CalendarFilter />
        </div> */}
        </div>
      </div>
      <div className={styles.content}>
        <SearchInputBlock
          activeIncident={activeIncident}
          setActiveIncident={(searchItem) => {
            const item = data?.items.find((item) => searchItem.id === item.id)
            setActiveIncident(item)
          }}
          complaints={data?.items}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        {data?.totalCount && data?.totalCount > 0 ? (
          <IncidentBlock activeIncident={activeIncident || null} />
        ) : null}
      </div>
    </div>
  )
}

export default ComplaintsView
