import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'

const assistApi = createApi({
  reducerPath: 'assistApi',
  baseQuery: fetchBaseQuery({
    baseUrl: window.origin,
    prepareHeaders: (headers) => {
      const storedToken = localStorage.getItem('kt-auth-react-v')
      const {api_token} = storedToken && JSON.parse(storedToken)

      if (api_token) {
        headers.set('Authorization', `Bearer ${api_token}`)
      }

      headers.set('Accept', 'application/json')
      headers.set('Content-Type', 'application/json')

      return headers
    },
  }),
  endpoints: () => ({}),
  tagTypes: ['AssistFiles'],
})

export default assistApi
