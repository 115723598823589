import {useQuery} from '@tanstack/react-query'
import {ContentItemService} from '../../../../../../api/services/ContentItemService'
import {RoleDto} from '../../../../../../api'

export const useCategoryRoles = (id: string) =>
  useQuery({
    queryFn: () => ContentItemService.contentItemGetAnalysisCategoriesRoles(id),
    queryKey: ['categoryRoles', id],
    select: (data) => data.map((item) => ({id: item.id, name: item.name} as RoleDto)),
  })
