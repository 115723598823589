import {createContext, useContext} from 'react'

interface ILoaderContext {
  loading: boolean
  setLoading: (loading: boolean, message?: string) => void
  message?: string
}

export const LoaderContext = createContext<ILoaderContext>({
  loading: false,
  setLoading: (loading: boolean, message?: string) => {},
})

const useLoader = () => useContext(LoaderContext)

export default useLoader
