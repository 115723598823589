import {Outlet} from 'react-router-dom'

import Header from '../Header/Header'

import type {ReactElement} from 'react'

import Sidebar from '../Sidebar/Sidebar'
import styles from './sass/Page.module.scss'

/**
 * Main page layout
 */
function Page(): ReactElement {
  return (
    <div id='modal' className={`${styles.page} page`}>
      <Header />
      <Sidebar />
      <Outlet />
    </div>
  )
}

export default Page
