import {useMutation} from '@tanstack/react-query'
import {ContentItemService} from '../../../../../../api'
import {queryClient} from '../../../../../../libs/react-query'

export const useAssignRoleToType = () =>
  useMutation({
    mutationFn: ContentItemService.contentItemAssignRolesToType,
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['typeRoles']}),
  })

export const useUnAssignRoleToType = () =>
  useMutation({
    mutationFn: ContentItemService.contentItemUnAssignRolesFromType,
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['typeRoles']}),
  })
