import {useMutation} from '@tanstack/react-query'
import {ContentItemService} from '../../../../../api'
import {queryClient} from '../../../../../libs/react-query'

export const useAssignPromptToStatus = () =>
  useMutation({
    mutationFn: ContentItemService.contentItemAssignPromptToStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['statuses']})
    },
  })

export const useUnAssignPromptToStatus = () =>
  useMutation({
    mutationFn: ContentItemService.contentItemUnAssignPromptToStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['statuses']})
    },
  })
