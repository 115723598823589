export const SEARCH_INPUT_DEFAULT_DELAY = 300;
export const CHAT_DEFAULT_PAGE_SIZE = 10;
export const SUGGESTIONS_PAGE_SIZE = 10;
export const THROTTLE_DEFAULT_LIMIT = 100;
export const DOTS = '...';
export const SUGGESTIONS = 'suggestions';
export const ASK_ME_SUGGESTIONS = 'askme/suggestions';
export const TEXT = 'text';
export const ASKME = 'askme';
export const CATEGORY = 'category';
export const SUBCATEGORY = 'subCategory';
export const MIN_TEXT_SUGGESTION_LENGTH = 10;
