import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../helpers'
import './powerBi.css'
const projects: ReadonlyArray<{image: string; title: string; link: string}> = [
  // {
  //   image: 'media/icons/powerBi/dashboard.png',
  //   title: 'Dashboard',
  //   link: '1',
  // },
  {
    image: 'media/icons/powerBi/facilities.png',
    title: 'Facilities',
    link: '2',
  },
  {
    image: 'media/icons/powerBi/clinical.png',
    title: 'Clinical',
    link: '3',
  },
  {
    image: 'media/icons/powerBi/nursecall.png',
    title: 'Nurse Call',
    link: '4',
  },
  {
    image: 'media/icons/powerBi/painchek.png',
    title: 'Paincheck',
    link: '5',
  },
]

const PowerBiTabs = () => {
  return (
    <div className='m-0'>
      {/*begin::Projects*/}
      <div className='m-0'>
        {/*begin::Heading*/}
        <h1 className='text-gray-800 fw-bold mb-6 mx-5'>Data Warehouse</h1>
        {/*end::Heading*/}

        {/*begin::Items*/}
        <div className='mb-10'>
          {projects.map((p) => (
            <Link
              key={p.link}
              to={`/data-warehouse/${p.link}`}
              className='custom-list d-flex align-items-center px-5 py-4'
            >
              {/*begin::Symbol*/}
              <div className='symbol symbol-40px me-5'>
                <span className='symbol-label'>
                  <img
                    src={'/' + toAbsoluteUrl(p.image)}
                    alt={p.title}
                    className={`align-self-center powerBiIcon`}
                  />
                </span>
              </div>
              {/*end::Symbol*/}

              {/*begin::Description*/}
              <div className='d-flex  flex-grow-1'>
                {/*begin::Title*/}
                <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{p.title}</h5>
                {/*end::Title*/}

                {/*begin::Link*/}
                <span className='text-gray-400 fw-bold'>{p.link}</span>
                {/*end::Link*/}
              </div>
              {/*begin::Description*/}
            </Link>
          ))}
        </div>
        {/*end::Items*/}
      </div>
      {/*end::Projects*/}
    </div>
  )
}

export {PowerBiTabs}
