import {useQuery} from '@tanstack/react-query'
import {PythiaService} from '../../../../../../api'

const getPythiaServiceByStatus = async (statusId: string) => {
  try {
    return await PythiaService.pythiaGetStatusServices(statusId)
  } catch (error) {
    console.error('Error fetching pythia services by status', error)
  }
  return []
}

export const useGetPythiaServicesByStatusQuery = (statusId: string) =>
  useQuery({
    queryKey: ['pythiaServices', statusId],
    queryFn: async () => getPythiaServiceByStatus(statusId),
  })
