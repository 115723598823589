import {useEffect} from 'react'
import {usePageData} from '../../../_metronic/layout/core'

export const Analytics = () => {
  // config.aside.secondaryDisplay
  const {setPageTitle, setPageBreadcrumbs} = usePageData()

  useEffect(() => {
    setPageTitle('Focus')
    setPageBreadcrumbs([])
  })
  return (
    <div style={{height: '100%'}}>
      <iframe
        style={{height: '100%'}}
        title='ANACC Demo'
        width='100%'
        src='https://app.powerbi.com/reportEmbed?reportId=e5b7b4e3-f853-429b-8472-5ba0d3213d1f&autoAuth=true&ctid=5136fe4d-f15e-498a-92cd-52593393b5c2'
        // frameborder='0'
        // allowFullScreen='true'
      ></iframe>
    </div>
  )
}
