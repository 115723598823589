import {useQuery} from '@tanstack/react-query'
import {ContentItemService} from '../../../../../api'

export const useContentItemTypes = (dashboardId?: string) =>
  useQuery({
    queryFn: () => ContentItemService.contentItemGetContentItemTypes(dashboardId),
    queryKey: ['contentItemTypes', dashboardId],
    select: (data) =>
      data.map(
        (type) =>
          ({
            id: type.id,
            key: type.id,
            label: type.name,
            name: type.name,
            description: type.description,

            data: {
              name: type.name,
              description: type.description,
              type: 'type',
            },
            children: [
              {
                key: type.id + 'categories',
                label: 'Action Categories',
                data: {
                  name: 'Action Categories',
                  description: '',
                },
                children:
                  type.analysisCategories?.map((category) => ({
                    id: category.id,
                    key: category.id,
                    label: category.name,
                    name: category.name,
                    data: {
                      name: category.name,
                      description: category.description,
                      type: 'category',
                    },
                    description: category.description,
                  })) ?? [],
              },
              {
                key: type.id + 'subCategory',
                label: 'Sub Categories',
                data: {
                  name: 'Sub Categories',
                  description: '',
                },
                children:
                  type.contentItemStatusCollection?.map((status) => ({
                    id: status.id,
                    key: status.id,
                    label: status.name,
                    name: status.name,
                    data: {
                      name: status.name,
                      description: status.description,
                      type: 'subcategory',
                    },
                    description: status.description,
                  })) ?? [],
              },
            ],
          } ?? [])
      ),
  })
