import {Card} from 'primereact/card'
import {Column} from 'primereact/column'
import {DataTable, DataTableStateEvent} from 'primereact/datatable'
import {UserQueryOptions} from '../../../../../queries/getUsers'
import {ChangeEvent, useState} from 'react'
import {IconField} from 'primereact/iconfield'
import {InputIcon} from 'primereact/inputicon'
import {InputText} from 'primereact/inputtext'
import styles from './roleList.styles.module.scss'
import {Sidebar} from 'primereact/sidebar'
import {useRoles} from '../../../../../queries/getRoles'
import {RoleDetails} from '../elements/roleDetails'
import {Button} from 'primereact/button'
import {RoleDto} from '../../../../../api'
import {useDeleteRole} from '../../../../../queries/deleteRole'
import {Dialog} from 'primereact/dialog'
import {useCreateRole} from '../../../../../queries/createRole'

const sortFieldMap: {[key: string]: string} = {
  username: 'UserName',
  email: 'Email',
  firstName: 'FirstName',
  lastName: 'LastName',
}

const sortFieldReverseMap: {[key: string]: string} = {
  UserName: 'username',
  Email: 'email',
  FirstName: 'firstName',
  LastName: 'lastName',
}

export const RolesList = () => {
  const [filters, setFilters] = useState<UserQueryOptions>({
    page: 1,
    pageSize: 50,
  })
  const [userOptionsVisible, setUserOptionsVisible] = useState('')
  const [roleName, setRoleName] = useState('')
  const createRole = useCreateRole()
  const [createRoleVisible, setCreateRoleVisible] = useState(false)
  const deleteRole = useDeleteRole()
  const [deleteDialog, setDeleteDialog] = useState('')
  const roles = useRoles(filters)
  const handlePageChange = (e: DataTableStateEvent) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: (e.page ?? 0) + 1,
      pageSize: e.rows,
    }))
  }

  const handleSortChange = (e: DataTableStateEvent) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: sortFieldMap[e.sortField],
      sortDescending: e.sortOrder === -1,
    }))
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: e.target.value,
    }))
  }

  const handleRowClick = (e: any) => {
    setUserOptionsVisible(e.data.id)
  }
  const handleDeleteRoleClick = () => {
    if (deleteDialog) deleteRole.mutate(deleteDialog, {onSuccess: () => setDeleteDialog('')})
  }

  const actionBodyTemplate = (rowData: RoleDto) => {
    return (
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          icon='pi pi-trash'
          rounded
          outlined
          severity='danger'
          onClick={() => setDeleteDialog(rowData.id ?? '')}
        />
      </div>
    )
  }
  const deleteRoleDialogFooter = (
    <>
      <Button label='No' icon='pi pi-times' outlined onClick={() => setDeleteDialog('')} />
      <Button label='Yes' icon='pi pi-check' severity='danger' onClick={handleDeleteRoleClick} />
    </>
  )

  const handleCreateRoleClick = () => {
    createRole.mutate(roleName, {onSuccess: () => setCreateRoleVisible(false)})
  }
  const createRoleDialogFooter = () => {
    return (
      <>
        <Button
          label='Cancel'
          icon='pi pi-times'
          outlined
          onClick={() => setCreateRoleVisible(false)}
        />
        <Button label='Save' icon='pi pi-check' onClick={handleCreateRoleClick} />
      </>
    )
  }

  const handleRoleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRoleName(e.target.value)
  }
  return (
    <div style={{margin: '5rem'}}>
      <Card>
        <div className={styles.search}>
          <IconField iconPosition='left' onChange={handleSearch}>
            <InputIcon className='pi pi-search'> </InputIcon>
            <InputText v-model='value1' placeholder='Search' />
          </IconField>
          <Button label='Add Role' icon='pi pi-plus' onClick={() => setCreateRoleVisible(true)} />
        </div>
        <DataTable
          dataKey='id'
          loading={roles.isLoading}
          tableStyle={{minWidth: '50rem'}}
          value={roles.data?.items}
          paginator
          rows={filters.pageSize}
          onPage={handlePageChange}
          filterDisplay='row'
          onRowClick={handleRowClick}
          selectionMode='single'
        >
          <Column sortable field={'name'} header='Name' />
          <Column sortable field={'description'} header='Description' />
          <Column body={actionBodyTemplate} style={{minWidth: '12rem'}} />
        </DataTable>
        <Dialog
          visible={!!deleteDialog}
          onHide={() => setDeleteDialog('')}
          footer={deleteRoleDialogFooter}
        >
          <div className='confirmation-content'>
            <i className='pi pi-exclamation-triangle mr-3' style={{fontSize: '2rem'}} />
            {deleteDialog && <span>Are you sure you want to delete the selected role?</span>}
          </div>
        </Dialog>
        <Dialog
          visible={createRoleVisible}
          onHide={() => setCreateRoleVisible(false)}
          footer={createRoleDialogFooter}
        >
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <label htmlFor='roleName'>Role Name</label>
            <InputText id='roleName' value={roleName} onChange={handleRoleNameChange} />
          </div>
        </Dialog>
      </Card>
    </div>
  )
}
