import { useTranslation } from 'react-i18next';

import DotsLoader from '../../components/DotsLoader/DotsLoader';
import Title from '../../components/Title/Title';
import {CATEGORY, SUBCATEGORY, TEXT} from '../../constants/general';
import SelectField from './components/SelectField';
import Textarea from './components/Textarea';

import type { ReactElement } from 'react';
import type { ISuggestionProps } from './interfaces/ISuggestionProps';

import styles from './sass/SuggestionView.module.scss';

function SuggestionView({
  isLoading, isEdit, onSubmit, register, control, errors, categories, subCategories,
}: ISuggestionProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.suggestion}>
      {isLoading ? <DotsLoader variant="centered" /> : (
        <div className={styles.container}>
          <Title text={isEdit ? t('suggestions.changeSuggestion') : t('suggestions.createSuggestion')} />
          <form onSubmit={onSubmit()}>
            <div className={styles.selectorsBlock}>
              <SelectField
                label={t('suggestions.categories')}
                value={CATEGORY}
                placeholder={t('suggestions.selectCategory')}
                control={control}
                data={categories}
                errors={errors?.category?.value}
              />
              <SelectField
                label={t('suggestions.subcategories')}
                value={SUBCATEGORY}
                placeholder={t('suggestions.selectSubcategory')}
                control={control}
                data={subCategories}
                errors={errors?.subCategory?.value}
              />
            </div>
            <Textarea
              label={t('suggestions.suggestionText')}
              value={TEXT}
              register={register}
              required
              errors={errors?.text}
            />
            <button
              type="submit"
              className={styles.submitButton}
            >
              {t('suggestions.submit')}
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default SuggestionView;
