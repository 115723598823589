import {useMutation} from '@tanstack/react-query'
import {queryClient} from '../libs/react-query'
import {IdentityService} from '../api'

type RemoveRolesOptions = {
  userId: string | null
  roleNames: string[]
}
const removeRoles = async ({userId, roleNames}: RemoveRolesOptions) =>
  await IdentityService.identityRemoveRolesFromUser(userId ?? '', roleNames)

export const useRemoveRole = () =>
  useMutation({
    mutationFn: removeRoles,
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['users']}),
  })
