import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'
import {InputText} from 'primereact/inputtext'
import {useCreateCategory} from '../api/createCategory'
import {useForm} from 'react-hook-form'
import {useState} from 'react'

type TypeData = {
  name: string
}

export const CreateAskMeCategoryButton = () => {
  const [visible, setVisible] = useState(false)
  const onHide = () => setVisible(false)
  const onClick = () => setVisible(true)
  const createType = useCreateCategory()
  const form = useForm<TypeData>()
  const {register, handleSubmit} = form
  const onSubmit = (data: TypeData) => {
    createType.mutate(data, {onSuccess: onHide})
  }
  const footerContent = (
    <div>
      <Button
        label='Cancel'
        icon='pi pi-times'
        onClick={() => setVisible(false)}
        className='p-button-text'
      />
      <Button
        label='Submit'
        icon='pi pi-check'
        autoFocus
        type='submit'
        loading={createType.isPending}
        form={'typeForm'}
      />
    </div>
  )

  return (
    <>
      <Button icon='pi pi-plus' label='Create Category' onClick={onClick} />
      <Dialog
        header='Create Category'
        visible={visible}
        style={{width: '30rem'}}
        onHide={onHide}
        footer={footerContent}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={'typeForm'}>
          <div className='p-fluid'>
            <div className='p-field'>
              <label htmlFor='name'>Name</label>
              <InputText id='name' type='text' {...register('name', {required: true})} />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  )
}
