import {useQuery} from '@tanstack/react-query'
import {DashboardsService} from '../../../api/services/DashboardsService'

export const useDashboard = (key: string) => {
  return useQuery({
    enabled: !!key,
    queryFn: () => DashboardsService.dashboardGetByKey(key),
    queryKey: [`dashboard-${key}`],
  })
}
