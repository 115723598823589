import {useQuery} from '@tanstack/react-query'

import type {ISubmenuItem} from 'src/app/components/NavItem/interfaces/INavItem'
import {ContentItemService} from "../../../api";


export const useStatus = (statusId: string | undefined) =>
  useQuery({
    queryFn: () => ContentItemService.contentItemGetContentItemStatusById(statusId!),
    queryKey: ['statuses', statusId],
    enabled: !!statusId,
    select: (response): ISubmenuItem => ({
      submenuId: response.id ?? '',
      submenuTitle: response.name ?? '',
      to: '',
    }),
  })

export const useSubcategories = (subCategoryId: string | undefined) =>
  useQuery({
    queryFn: () => ContentItemService.contentItemGetContentItemStatusById(subCategoryId!),
    queryKey: ['statuses', subCategoryId],
    enabled: !!subCategoryId,
  })
