import {request as __request} from '../core/request'
import {CancelablePromise} from '../core/CancelablePromise'
import {OpenAPI} from '../core/OpenAPI'

export class AssistService {
  public static assistGerenatePrompt(
    prompt: string,
    actionId?: string
  ): CancelablePromise<{result: string; success: boolean}> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Assist/prompts',
      body: {prompt, actionId},
    })
  }

  public static assistExportHtmlToFile(html: string) {
    // return __request<Response>(OpenAPI, {
    //   method: 'POST',
    //   url: '/api/Assist/export',
    //   body: {html},
    //   headers: {
    //     'Content-Type':
    //       'application / vnd.openxmlformats - officedocument.wordprocessingml.document',
    //   },
    // })
    const storedToken = localStorage.getItem('kt-auth-react-v')
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {api_token} = storedToken && JSON.parse(storedToken)
    
    return fetch('/api/Assist/export', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${api_token}`,
      },
      body: JSON.stringify({html: html}),
    })
  }
}
