import { useTranslation } from 'react-i18next';

import Pagination from '../../components/Pagination/Pagination';
import SearchInput from '../../components/SearchInput/SearchInput';
import SuggestionsList from '../../components/SuggestionsList/SuggestionsList';
import Title from '../../components/Title/Title';

import type { ReactElement } from 'react';
import type { ISuggestions } from './interfaces/ISuggestions';

import styles from './sass/SuggestionsView.module.scss';

function SuggestionsView({
  suggestions, searchTerm, onPageClick, onChangeHandler,
}: ISuggestions): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.suggestions}>
      <div className={styles.container}>
        <div className={styles.pageHeader}>
          <Title text={`${t('suggestions.suggestions')}`} />
          <SearchInput
            searchTerm={searchTerm}
            onChangeHandler={onChangeHandler}
          />
        </div>
        <SuggestionsList
          suggestions={suggestions?.data}
        />
        <Pagination
          currentPage={suggestions?.pageNumber}
          onPageClick={onPageClick}
          totalPageCount={suggestions?.totalPages}
        />
      </div>
    </div>
  );
}

export default SuggestionsView;
