import {useEffect} from 'react'
import {usePageData} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import {useExternalApplication} from '../../../queries/getExternalAppication'

export const Applications = () => {
  // config.aside.secondaryDisplay
  const {setPageTitle, setPageBreadcrumbs} = usePageData()
  const {id} = useParams()
  const application = useExternalApplication(id)
  useEffect(() => {
    if (application.isSuccess) {
      setPageTitle(application.data?.appName!)
      setPageBreadcrumbs([])
    }
  }, [application.isLoading, id])
  return (
    <div style={{height: '100%'}}>
      {application.isSuccess && (
        <iframe
          style={{height: '100%'}}
          title={application.data?.appName}
          width='100%'
          src={application.data?.appLink}
        ></iframe>
      )}
    </div>
  )
}
