import {useMutation} from '@tanstack/react-query'
import {ContentItemService} from '../../../../../api'
import {queryClient} from '../../../../../libs/react-query'

export const useCreateStatus = () =>
  useMutation({
    mutationFn: ContentItemService.contentItemCreate4,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['statuses']})
      queryClient.invalidateQueries({queryKey: ['contentItemTypes']})
    },
  })
