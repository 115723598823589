import Portal from '../Portal/Portal';

import type { IModal } from './interfaces/IModal';

import styles from './sass/Modal.module.scss';

export default function Modal({ children, isShow, onClick }: IModal) {
  return (
    <Portal selector="#modal">
      <div
        className={styles.container}
        role="presentation"
        onClick={onClick}
      >
        {isShow ? children : null}
      </div>
    </Portal>
  );
}
