// import {Button} from 'primereact/button'
import React, {useEffect, useState} from 'react'
import {InputText} from 'primereact/inputtext'
import {usePythiaServices} from '../../../../PythiaV3/pages/Complaints/queries/getPythiaServices'
import {DataTable, DataTableFilterMeta} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {IconField} from 'primereact/iconfield'
import {InputIcon} from 'primereact/inputicon'
import {FilterMatchMode} from 'primereact/api'
import {Skeleton} from 'primereact/skeleton'
import Button from '../../../../PythiaV3/components/Button/Button'
import {ButtonVariant, ButtonWidthVariant} from '../../../../../common/constants/enams'
import {useSubcategories} from '../../../../../common/queries/getStatus'

interface Props {
  statusId?: string
  onAction?: (actionId: string) => void
}

export const AiRequestsList = ({statusId, onAction}: Props) => {
  const {data: status} = useSubcategories(statusId)
  const {data, isLoading} = usePythiaServices()
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  })
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  useEffect(() => {
    const filtered = data?.filter((item) => {
      return status?.analysisPromptContentItemStatuses?.some(
        (analysis) => analysis.promptId === item.id
      )
    })
    setFilteredData(filtered ?? [])
  }, [status, data])

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let _filters = {...filters}

    // @ts-ignore
    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <IconField iconPosition='left'>
          <InputIcon className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </IconField>
      </div>
    )
  }

  if (isLoading) {
    return (
      <>
        <Skeleton width='10rem' height='3rem' className='mb-2'></Skeleton>
        <Skeleton height='8rem' className='mb-2'></Skeleton>
        <Skeleton height='3rem' className='mb-2'></Skeleton>
        <Skeleton height='12rem' style={{marginTop: '2rem'}} className='mb-2'></Skeleton>
        <Skeleton height='12rem' style={{marginTop: '2rem'}} className='mb-2'></Skeleton>
        <Skeleton height='12rem' style={{marginTop: '2rem'}} className='mb-2'></Skeleton>
        <Skeleton height='12rem' style={{marginTop: '2rem'}} className='mb-2'></Skeleton>
      </>
    )
  }

  return (
    <div>
      <h3 style={{marginBottom: '2.5rem'}}>AI Requests</h3>
      <DataTable
        value={filteredData}
        // selection={selectedPrompts}
        // selectionMode={}
        // onSelectionChange={handleSelect}
        dataKey='id'
        filters={filters}
        // filters={status?.analysisPromptContentItemStatuses}
        globalFilterFields={['name', 'category', 'notes']}
        header={renderHeader()}
      >
        <Column sortable field='name' header='Name' />
        <Column sortable field='category' header='Category' />
        <Column sortable field='notes' header='Description' />
        <Column
          field='execute'
          header='Action'
          body={(rowData: any) => (
            <Button
              title='AI Request'
              variant={ButtonVariant.PRIMARY}
              widthVariant={ButtonWidthVariant.EXPLORE}
              onClick={() => {
                onAction && onAction(rowData.id)
              }}
            />
          )}
        />
      </DataTable>
    </div>
  )
}
