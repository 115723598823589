import {useQuery} from '@tanstack/react-query'
import {PythiaService} from 'src/api'

const getCategories = async () => {
  return await PythiaService.pythiaGetCategories()
}

export const useCategories = () =>
  useQuery({
    queryKey: ['askMeCategories'],
    queryFn: getCategories,
    select: (data) =>
      data.map((category) => ({
        id: category.id,
        key: category.id,
        name: category.name,
        data: {
          name: category.name,
        },
        children:
          category.subCategories?.map((subCategory) => ({
            id: subCategory.id,
            key: subCategory.id + 'subCategory',
            label: subCategory.name,
            name: subCategory.name,
            data: {
              name: subCategory.name,
              parentCategory: subCategory.parentCategory,
              actionId: subCategory.actionId,
            },
          })) ?? [],
      })),
  })
