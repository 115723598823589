/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignRoleToExternalApplicationCommand } from '../models/AssignRoleToExternalApplicationCommand';
import type { CreateExternalApplicationCommand } from '../models/CreateExternalApplicationCommand';
import type { ExternalApplication } from '../models/ExternalApplication';
import type { ExternalApplicationDto2 } from '../models/ExternalApplicationDto2';
import type { UnassignRoleFromExternalApplicationCommand } from '../models/UnassignRoleFromExternalApplicationCommand';
import type { UpdateExternalApplicationCommand } from '../models/UpdateExternalApplicationCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExternalApplicationsService {

    /**
     * @param requestBody 
     * @returns number 
     * @throws ApiError
     */
    public static externalApplicationsCreate(
requestBody: CreateExternalApplicationCommand,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ExternalApplications',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ExternalApplicationDto2 
     * @throws ApiError
     */
    public static externalApplicationsGetAll(): CancelablePromise<Array<ExternalApplicationDto2>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ExternalApplications',
        });
    }

    /**
     * @param id 
     * @returns ExternalApplication 
     * @throws ApiError
     */
    public static externalApplicationsGet(
id: string,
): CancelablePromise<ExternalApplication> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ExternalApplications/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static externalApplicationsUpdate(
id: string,
requestBody: UpdateExternalApplicationCommand,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/ExternalApplications/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns binary 
     * @throws ApiError
     */
    public static externalApplicationsDelete(
id: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/ExternalApplications/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static externalApplicationsAssignRole(
requestBody: AssignRoleToExternalApplicationCommand,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ExternalApplications/assign-role',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns binary 
     * @throws ApiError
     */
    public static externalApplicationsUnassignRole(
requestBody: UnassignRoleFromExternalApplicationCommand,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ExternalApplications/unassign-role',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
