/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CategoriesListDto } from '../models/CategoriesListDto';
import type { Category } from '../models/Category';
import type { CategoryDto } from '../models/CategoryDto';
import type { ChatHistory } from '../models/ChatHistory';
import type { CreateChatHistoryDto } from '../models/CreateChatHistoryDto';
import type { CreateSuggestionDto } from '../models/CreateSuggestionDto';
import type { PaginatedResultOfChatHistory } from '../models/PaginatedResultOfChatHistory';
import type { PaginatedResultOfSuggestion } from '../models/PaginatedResultOfSuggestion';
import type { PythiaOffering } from '../models/PythiaOffering';
import type { PythiaServiceDto } from '../models/PythiaServiceDto';
import type { PythiaStatusServiceDto } from '../models/PythiaStatusServiceDto';
import type { Suggestion } from '../models/Suggestion';
import type { UpdateChatHistoryDto } from '../models/UpdateChatHistoryDto';
import type { UpdateSuggestionDto } from '../models/UpdateSuggestionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PythiaService {

    /**
     * @returns PythiaOffering 
     * @throws ApiError
     */
    public static pythiaGetOracleOfferings(): CancelablePromise<Array<PythiaOffering>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Pythia/oracle-offerings',
        });
    }

    /**
     * @param typeId 
     * @returns PythiaServiceDto 
     * @throws ApiError
     */
    public static pythiaGetAdminOracleOfferings(
typeId?: string,
): CancelablePromise<Array<PythiaServiceDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Pythia/admin/oracle-offerings',
            query: {
                'typeId': typeId,
            },
        });
    }

    /**
     * @param statusId 
     * @returns PythiaStatusServiceDto 
     * @throws ApiError
     */
    public static pythiaGetStatusServices(
statusId?: string,
): CancelablePromise<Array<PythiaStatusServiceDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Pythia/status-services',
            query: {
                'statusId': statusId,
            },
        });
    }

    /**
     * @param subCategory 
     * @param skip 
     * @param pageSize 
     * @returns PaginatedResultOfChatHistory 
     * @throws ApiError
     */
    public static pythiaGetChatHistory(
subCategory?: string,
skip?: number,
pageSize?: number,
): CancelablePromise<PaginatedResultOfChatHistory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Pythia/ChatHistory',
            query: {
                'SubCategory': subCategory,
                'Skip': skip,
                'PageSize': pageSize,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ChatHistory 
     * @throws ApiError
     */
    public static pythiaCreateMessage(
requestBody: CreateChatHistoryDto,
): CancelablePromise<ChatHistory> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Pythia/ChatHistory',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ChatHistory 
     * @throws ApiError
     */
    public static pythiaGetMessage(
id: string,
): CancelablePromise<ChatHistory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Pythia/ChatHistory/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns binary 
     * @throws ApiError
     */
    public static pythiaDeleteMessage(
id: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Pythia/ChatHistory/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns ChatHistory 
     * @throws ApiError
     */
    public static pythiaUpdateMessage(
id: string,
requestBody: UpdateChatHistoryDto,
): CancelablePromise<ChatHistory> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/Pythia/ChatHistory/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param subCategory 
     * @param search 
     * @param startsWith 
     * @param pageNumber 
     * @param pageSize 
     * @returns PaginatedResultOfSuggestion 
     * @throws ApiError
     */
    public static pythiaGetSuggestions(
subCategory?: string | null,
search?: string | null,
startsWith?: string | null,
pageNumber?: number,
pageSize?: number,
): CancelablePromise<PaginatedResultOfSuggestion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Pythia/Suggestions',
            query: {
                'SubCategory': subCategory,
                'Search': search,
                'StartsWith': startsWith,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Suggestion 
     * @throws ApiError
     */
    public static pythiaCreateSuggestion(
requestBody: CreateSuggestionDto,
): CancelablePromise<Suggestion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Pythia/Suggestions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns Suggestion 
     * @throws ApiError
     */
    public static pythiaGetSuggestionById(
id: string,
): CancelablePromise<Suggestion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Pythia/Suggestions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns binary 
     * @throws ApiError
     */
    public static pythiaDeleteSuggestion(
id: string,
): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Pythia/Suggestions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Suggestion 
     * @throws ApiError
     */
    public static pythiaUpdateSuggestion(
id: string,
requestBody: UpdateSuggestionDto,
): CancelablePromise<Suggestion> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/Pythia/Suggestions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CategoriesListDto 
     * @throws ApiError
     */
    public static pythiaGetCategories(): CancelablePromise<Array<CategoriesListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Pythia/Categories',
        });
    }

    /**
     * @param requestBody 
     * @returns Category 
     * @throws ApiError
     */
    public static pythiaCreateCategory(
requestBody: CategoryDto,
): CancelablePromise<Category> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Pythia/Categories',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns Category 
     * @throws ApiError
     */
    public static pythiaDeleteCategory(
id?: string,
): CancelablePromise<Category> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Pythia/Categories',
            query: {
                'id': id,
            },
        });
    }

}
