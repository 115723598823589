import { SUGGESTIONS_PAGE_SIZE } from '../../../../constants/general';
import {
  DELETE, GET, PATCH, POST,
} from '../../../../constants/requestMethods';
import { SUGGESTIONS } from '../../../../constants/requestUrls';
import { SUGGESTIONS_TAG } from '../../../../constants/tags';
import mainApi from '../../../apis/main';

import type { IPaginatedResponse } from '../../../types/IPaginatedResponse';
import type { IGetSuggestionParams } from '../../chat/interfaces/IGetSuggestionParams';
import type ISuggestion from '../../chat/interfaces/ISuggestion';
import type { ICreateSuggestionParams } from '../interfaces/ICreateSuggestionParams';
import type { IDeleteResponse } from '../interfaces/IDeleteResponse';

const suggestionsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSuggestions: builder.query<IPaginatedResponse<ISuggestion>, IGetSuggestionParams>({
      query: ({
        search, pageNumber, startsWith, pageSize = SUGGESTIONS_PAGE_SIZE,
        subCategory,
      }) => ({
        url: `${SUGGESTIONS}`,
        params: {
          PageNumber: pageNumber,
          PageSize: pageSize,
          Search: search,
          StartsWith: startsWith,
          SubCategory: subCategory,
        },
        method: GET,
      }),
      providesTags: [SUGGESTIONS_TAG],
    }),
    getSuggestion: builder.query<ISuggestion, string | undefined>({
      query: (id) => ({
        url: `${SUGGESTIONS}/${id}`,
        method: GET,
      }),
      providesTags: [SUGGESTIONS_TAG],
    }),
    addSuggestion: builder.mutation<ISuggestion, ICreateSuggestionParams>({
      query: (body) => ({
        url: `${SUGGESTIONS}`,
        method: POST,
        body,
      }),
      invalidatesTags: [SUGGESTIONS_TAG],
    }),
    deleteSuggestion: builder.mutation<IDeleteResponse, string>({
      query: (id) => ({
        url: `${SUGGESTIONS}/${id}`,
        method: DELETE,
      }),
      invalidatesTags: [SUGGESTIONS_TAG],
    }),
    updateSuggestion: builder.mutation<ISuggestion, { id: string } & Partial<ICreateSuggestionParams>>({
      query: ({
        id,
        subCategory,
        text,
      }) => ({
        url: `${SUGGESTIONS}/${id}`,
        method: PATCH,
        body: { subCategory, text },
      }),
      invalidatesTags: [SUGGESTIONS_TAG],
    }),
  }),
});

export const {
  useDeleteSuggestionMutation,
  useFetchSuggestionsQuery,
  useGetSuggestionQuery,
  useUpdateSuggestionMutation,
  useAddSuggestionMutation,
} = suggestionsApi;

export default suggestionsApi;
