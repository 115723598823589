import type { ReactElement } from 'react';
import type { IButton } from './interfaces/IButton';

import styles from './sass/Button.module.scss';

export default function Button({
  onClick, buttonStyle, icon, disabled,
}: IButton): ReactElement {
  const onButtonClick = disabled ? () => {} : onClick;

  return (
    <div
      className={`${styles.button} ${buttonStyle || ''} ${disabled ? styles.disabled : ''}`}
      onClick={onButtonClick}
      role="presentation"
    >
      {icon}
    </div>
  );
}
