import {Avatar} from 'primereact/avatar'
import {TabMenu, TabMenuTabChangeEvent} from 'primereact/tabmenu'
import {useParams} from 'react-router-dom'
import {useCategory} from '../../Pythia/queries/getCategory'
import {Skeleton} from 'primereact/skeleton'
import {PromptsView} from './categories/promptsView'
import {RolesView} from './categories/rolesView'
import {useState} from 'react'
import {AssignPromptsView} from './categories/assignPromptsView'
import {Button} from 'primereact/button'
import {useUpdateCategory} from '../../Pythia/queries/updateCategory'
import {InputText} from 'primereact/inputtext'

const items = [
  {label: 'Actions', icon: 'pi pi-comment'},
  {label: 'Manage Actions', icon: 'pi pi-wrench'},
  {label: 'Roles', icon: 'pi pi-users'},
]

const viewMap: {[key: number]: JSX.Element} = {
  0: <PromptsView />,
  1: <AssignPromptsView />,
  2: <RolesView />,
}

export const CategoriesView = () => {
  const {categoryId} = useParams()
  const [activeIndex, setActiveIndex] = useState(0)
  const category = useCategory(categoryId ?? '')
  const [isEditing, setIsEditing] = useState(false)
  const [categoryName, setCategoryName] = useState(category.data?.name ?? '')
  const updateCategory = useUpdateCategory()
  const categoryLabel = `${category.data?.name?.charAt(0).toUpperCase()}${category.data?.name
    ?.charAt(1)
    .toUpperCase()}`
  const handleTabChange = (e: TabMenuTabChangeEvent) => {
    setActiveIndex(e.index)
  }

  const handleCategoryNameChange = (e: any) => {
    setCategoryName(e.target.value)
  }

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }

  const handleUpdateClick = () => {
    updateCategory.mutate({id: categoryId ?? '', name: categoryName}, {})
    setIsEditing(!isEditing)
  }

  return (
    <div>
      {category.isLoading ? (
        <>
          <Skeleton shape='circle' size='5rem'></Skeleton>
          <Skeleton className='mb-2'></Skeleton>
          <Skeleton width='10rem' className='mb-2'></Skeleton>
          <Skeleton width='5rem' className='mb-2'></Skeleton>
          <Skeleton height='2rem' className='mb-2'></Skeleton>
          <Skeleton width='10rem' height='4rem'></Skeleton>
        </>
      ) : (
        <>
          <div style={{position: 'sticky', backgroundColor: 'white', top: 0, zIndex: 100}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{display: 'flex'}}>
                <div style={{margin: '4px 20px 0px 0px'}}>
                  <Avatar label={categoryLabel} shape='circle' size='xlarge' />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                  }}
                >
                  {isEditing ? (
                    <div style={{display: 'flex'}}>
                      <InputText value={categoryName} onChange={handleCategoryNameChange} />
                      <Button
                        icon='pi pi-check'
                        text
                        severity='info'
                        size='large'
                        onClick={handleUpdateClick}
                        // onClick={handleEditClick}
                        // disabled={deleteType.isPending}
                      />
                    </div>
                  ) : (
                    <h3 style={{paddingLeft: '16.250px'}}>{category.data?.name}</h3>
                  )}
                </div>
              </div>
              <div style={{display: 'flex'}}>
                <Button
                  icon='pi pi-pencil'
                  text
                  severity='info'
                  size='large'
                  onClick={handleEditClick}
                  disabled={updateCategory.isPending}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: '2rem',
                width: '100%',
              }}
            >
              <TabMenu model={items} activeIndex={activeIndex} onTabChange={handleTabChange} />
            </div>
          </div>
          <div style={{padding: '1rem'}}>{viewMap[activeIndex]}</div>
        </>
      )}
    </div>
  )
}
